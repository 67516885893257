import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import SectionApplicationProcess from "components/SectionApplicationProcess/SectionApplicationProcess";

export interface PageProgramDetailProps {
  className?: string;
}

const PageProgramDetail: FC<PageProgramDetailProps> = ({ className = "" }) => {
  return (
    <div className={`page-program-detail ${className}`}>
      <Helmet>
        <title>URBAN Care Young Talent Platform | Program Detayı</title>
      </Helmet>

      <div className={`container py-14 md:pt-24 md:pb-14 ${className}`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <Heading border desc="" className="mb-0">
            <span>‘DANSTA FIRSAT EŞİTLİĞİ’</span>
            <br /> PROGRAM DETAYI
          </Heading>
          <div className="gdpr-wrapper">
            <p>
              <strong>URBAN Care Young Talent Platform</strong> ile 2024 yılı
              boyunca dans kategorisinde genç yeteneklere hayallerine giden
              yolda destek olmayı hedeflemektedir. Platformun ilk programı olan
              <strong>“Dansta Fırsat Eşitliği”</strong> katılımcılara{" "}
              <strong>Avrupa’nın önde gelen okullarında</strong> yaz döneminde
              ulaşım, eğitim, konaklama ve sosyal hayatın da dahil olduğu
              aşağıdaki dans kategorilerinde dans eğitim hakkı sunuyor.
            </p>
            <div className={`relative inline-block shrink-0 py-4`}>
              <h3 className="default-heading mb-0 flex-shrink-0 text-2xl">
                DANS KATEGORİLERİ
              </h3>
              <ul className="default red flex flex-wrap flex-col gap-y-3 md:gap-y-5 gap-x-6 pt-3 justify-start !pl-0">
                <li>Bale</li>
                <li>Çağdaş - Modern Dans</li>
                <li>Sokak Dansı</li>
              </ul>
            </div>
            <p>
              <strong>Çağdaş - Modern ve Sokak Dansı</strong> kategorilerinde
              <strong>18-28</strong>, <strong>Bale</strong> kategorisinde{" "}
              <strong>16-20</strong> yaş arasındaki genç dansçıların başvuruları
              anasayfada yer alan <strong>“Şimdi Başvur”</strong> bölümü ile
              alınacak.
            </p>
            <p>
              Ön eleme değerlendirmesinin ardından final değerlendirmesi{" "}
              <strong>fiziksel olarak gerçekleştirilecektir</strong>. Fiziki
              elemeler sonucunda <strong>hak kazanan dansçılar</strong> bu
              haklarından <strong>yaz döneminde yararlanacaklardır</strong>.
            </p>
          </div>
        </div>
      </div>

      <SectionApplicationProcess />

      <div className={`container py-14 md:pb-24`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <div className="gdpr-wrapper">
            <p>
              <strong>Hak kazanan katılımcıların</strong> eğitime katılım
              sağlaması için <strong>gerekli vize işlemleri</strong> de platform
              aracılığıyla sağlanacaktır.{" "}
            </p>
            <p>
              Başvuru yapmayı planlayan katılımcıların belirlenen dans
              kategorilerinde halihazırda kabul aldığı bir yaz okulu olması
              durumunda da burs fonuna başvurma imkanı sağlanmaktadır.{" "}
              <strong>Çağdaş – Modern, Sokak Dansı ve Bale</strong> dans
              kategorilerinin tüm alt branşları projeye dahildir.{" "}
            </p>
            <p>
              <strong>
                “Gençlerin öncülüğünde, yetişkinlerin rehberliğinde”
              </strong>{" "}
              prensibiyle hareket eden ve gençlere destek vererek toplumsal
              sorunlara çözüm üreten projeler gerçekleştirmeleri için alan
              yaratan <strong>Toplum Gönülleri Vakfı (TOG)</strong> iş
              birliğiyle gerçekleştirilen proje, Sanat Yönetmeni, Koreograf,
              Eğitmen Beyhan Murphy’nin başkanlığını yaptığı danışma kurulu ve
              sanatçı, dans ve hareket terapisti <strong>Ekin Bernay</strong>’ın
              koordinatörlüğünde çalışmalarını sürdürmektedir.
            </p>
            <p>
              İlerleyen dönemde <strong>Ekin Bernay</strong>, seçici kurul
              üyeleri ve eğitim imkanı sağlanan gençlerle birlikte, Anadolu’nun
              farklı köşelerinde ve özellikle afet bölgesinde yer alan{" "}
              <strong>TOG Destek Merkezleri</strong>’nde gönüllü olarak{" "}
              <strong>URBAN Care Dans Atölyeleri</strong> gerçekleştirilmesi
              planlanmaktadır.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageProgramDetail;
