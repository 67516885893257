import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface SectionAboutProgramProps {
  className?: string;
}

const SectionAboutProgram: FC<SectionAboutProgramProps> = ({
  className = "pt-4",
}) => {
  return (
    <div
      className={`section-about-program relative flex items-center ${className}`}
    >
      <div className={`flex flex-col gap-7 lg:gap-12`}>
        <Heading className="mb-0 items-center" isCenter border desc="">
          <span>PROGRAM</span> HAKKINDA
        </Heading>
        <div className="flex flex-col lg:flex-row gap-12">
          <p className="text-left lg:text-right">
            URBAN Care Young Talent Platformu, gençlerin hayallerini
            gerçekleştirme fırsatı sunmak için fon oluşturulmasını
            amaçlamaktadır. Her sene yeni bir odak noktası belirleyerek
            kapsayıcı olarak tüm gençlerle bağ kurması hedeflenmektedir.
            <br />
            <br />
            2024 yılında ülkemizde dans kültürünün yaygınlaştırılmasına katkı
            sağlama, gençler arasından potansiyel dansçıları ortaya çıkarma,
            onlara fırsat tanıma ve uluslararası arenaya açılma yolculuklarına
            eşlik etme hedefiyle yola çıkarak gençlerin hayallerini ileri taşıma
            yolculuğunda güçlü bir bağ olacağız.
          </p>

          <div className={`relative inline-block shrink-0 lg:mr-36`}>
            <h3 className="default-heading mb-0 flex-shrink-0 text-2xl">
              DANS KATEGORİLERİ
            </h3>
            <ul className="default red flex flex-wrap flex-col gap-y-3 md:gap-y-5 gap-x-6 pt-6 justify-start">
              <li>Bale</li>
              <li>Çağdaş - Modern Dans</li>
              <li>Sokak Dansı</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionAboutProgram;
