import React from "react";
import HeroSearchForm2Mobile from "./HeroSearchForm2Mobile";
import HeroSearchForm2RealEstateMobile from "./HeroSearchForm2RealEstateMobile";
import { PathName } from "routers/types";
import { useLocation } from "react-router-dom";

const HeroSearchForm2MobileFactory = () => {
  const pathname = useLocation().pathname;
  return <HeroSearchForm2Mobile />;
};

export default HeroSearchForm2MobileFactory;
