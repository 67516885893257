import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import Heading from "components/Heading/Heading";
import selen from "images/team/selen-yorgun-tavit.jpg";
import beyhan from "images/team/beyhan-murphy.jpg";
import korhan from "images/team/korhan-basaran.jpg";
import duygu from "images/team/duygu-etikan.jpg";
import melih from "images/team/melih-mertel.jpg";
import aydan from "images/team/aydan-uysal.jpg";
import ayse from "images/team/ayse-kirimli.jpg";
//
import imageLeft from "images/team/team-left.svg";
import imageRight from "images/team/team-right.svg";

export interface SectionTeamProps {
  className?: string;
}

const data = [
  {
    id: "01",
    img: beyhan,
    nameSurname: "Beyhan Murphy",
    title:
      "<strong>UYTP Kurul Başkanı</strong><br class='custom-br' /> Sanat Yönetmeni,<br class='custom-br' /> Koreograf, Eğitmen.",
  },
  {
    id: "02",
    img: selen,
    nameSurname: "Selen Yorgun Tavit",
    title: "Selen Kozmetik CEO, <br class='custom-br' />URBAN Care Kurucusu",
  },
  {
    id: "06",
    img: aydan,
    nameSurname: "Aydan Uysal",
    title:
      "Koreograf, Eğitmen, Dansçı,<br class='custom-br' /> Breaking Milli Takım Antrenörü",
  },
  {
    id: "07",
    img: ayse,
    nameSurname: "Ayşe Kırımlı",
    title: "TOG Genel Müdürü",
  },
  {
    id: "04",
    img: duygu,
    nameSurname: "Duygu Etikan",
    title:
      "Dansçı & Koreograf, Hip<br class='custom-br' /> Hop Ladies Turkey & Istanbul Street Dance Co-Founder",
  },
  {
    id: "03",
    img: korhan,
    nameSurname: "Korhan Başaran",
    title: "Artistic Director (Company RAu), Bağımsız Koreograf - Yönetmen",
  },

  {
    id: "05",
    img: melih,
    nameSurname: "Melih Mertel",
    title: "Bale Sanatçısı, Eğitmen",
  },
];

const SectionTeam: FC<SectionTeamProps> = ({ className = "" }) => {
  return (
    <div className={`section-application-proccess ${className}`}>
      <Heading border isCenter desc="" className="mb-0 items-center container">
        <span>SEÇİCİ</span> KURUL
      </Heading>
      <div className="relative md:container flex flex-row lg:flex-wrap gap-6 md:gap-9 pt-9 overflow-y-hidden md:overflow-y-visible overflow-x-scroll md:overflow-x-visible no-scrollbar justify-start lg:justify-center lg:!max-w-[985px] 2xl:!max-w-[1210px]">
        {data.map((item, index) => (
          <div
            className="flex flex-col flex-shrink-0 min-w-[211px] max-w-[211px] gap-3 items-center relative hover:scale-105 transition-all duration-200 first:pl-4 md:first:pl-0 last:pr-4 md:last:pr-0"
            key={index}
          >
            <div className="border border-black rounded-full p-[10px]">
              <NcImage
                src={item.img}
                alt={item.nameSurname}
                className="w-[120px] h-[180px] rounded-full"
              />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <h5
                className="text-2xl leading-8 font-bold text-center"
                dangerouslySetInnerHTML={{ __html: item.nameSurname }}
              />
              <p
                className="text-cGrey2 text-base text-center"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
          </div>
        ))}
        <img
          className="absolute hidden lg:block -left-44 2xl:-left-24 bottom-16"
          src={imageLeft}
          alt="Seçici Kurul"
        />
        <img
          className="absolute hidden lg:block lg:-right-48 2xl:-right-24 bottom-16"
          src={imageRight}
          alt="Seçici Kurul"
        />
      </div>
    </div>
  );
};

export default SectionTeam;
