import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageCookieProps {
  className?: string;
}

const PageCookie: FC<PageCookieProps> = ({ className = "" }) => {
  return (
    <div className={`page-cookie ${className}`}>
      <Helmet>
        <title>Urban Care Young Talent Platform | Çerezlerin İşlenmesi</title>
      </Helmet>

      <div className={`container py-14 md:py-24 ${className}`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <div
            className={`section-heading relative flex flex-col sm:flex-row justify-between`}
          >
            <div className="text-center w-full max-w-[60rem] mx-auto">
              <div className="relative p-0">
                <h2 className={`default-heading mb-0 items-center`}>
                  URBAN CARE YOUNG TALENT PLATFORM ÇEREZLERLE İŞLENEN KİŞİSEL
                  VERİLERE YÖNELİK AYDINLATMA METNİ
                </h2>
                <div
                  className={`mt-3 !max-w-[220px] md:max-w-[unset] w-full h-[2px] left-0 -bottom-3 m-auto bg-main`}
                />
              </div>
            </div>
          </div>
          <div className="gdpr-wrapper">
            <p>
              <strong>
                Selen Kozmetik Sanayi Ticaret Anonim Şirketi (“Selen Kozmetik”,
                “Urban Care” veya “Urban Care Young Talent Platform”)
              </strong>{" "}
              olarak kişisel verilerinizin korunmasına değer veriyor,
              dolayısıyla sizin de aynı hassasiyeti göstermenizi bekliyoruz. Bu
              sebeple hangi kişisel verilerinizi ne amaçla kullandığımızı
              detaylarıyla anlatan bu metni okumanız, bunların idari ve teknik
              güvenliğinden <strong>“Veri Sorumlusu”</strong> sıfatıyla sorumlu
              olan <strong>Urban Care'in</strong> yükümlülüklerini net bir
              şekilde anladığınızı göstermeniz bizim için çok önemlidir.
            </p>

            <h4>Neden Bu Metni Okuyorum?</h4>

            <p>
              <strong>7 Nisan 2016</strong> tarihinde yürürlüğe giren{" "}
              <strong>
                6698 sayılı Kişisel Verilerin Korunması Kanununun (“KVKK”) 10.
                maddesi
              </strong>
              , kişisel verileri işlenen (kullanılan) kişilerin{" "}
              <strong>(KVKK'da ilgili kişi olarak ifade edilmektedir)</strong>{" "}
              bu verileri kullanan <strong>“Veri Sorumlusu”</strong> tarafından
              bilgilendirilmesini zorunlu kılmaktadır. Bizler de{" "}
              <strong>Veri Sorumlusu</strong> olarak{" "}
              <u>
                sizler hakkında işleyeceğimiz kişisel verilerle ilgili
                bilgilendirildiğinizi ispat etmek durumundayız.
              </u>
            </p>

            <h4>
              Kullanılan Kişisel Verileriniz Neler Olup Hangi Yollarla Elde
              Edilmektedir?
            </h4>

            <p>
              <strong>Urban Care</strong> olarak aydınlatma metni kapsamındaki
              kişisel verilerinizi, internet sitemizi ziyaretiniz ile birlikte
              dijital yollarla{" "}
              <strong>
                (KVKK'da “otomatik olan” yöntem şeklinde ifade edilmektedir.)
              </strong>{" "}
              elde etmekteyiz. Elde edilen kişisel verilerinizin neler olduğu ve
              elde etmemizin hukuki sebebi aşağıda açıklanmıştır:
            </p>
            <div className="overflow-x-auto no-scrollbar">
              <table>
                <tbody>
                  <tr>
                    <th>Veri Kategorisi</th>
                    <th>İşlenen Kişisel Verileriniz</th>
                    <th>Elde Etme Hukuki Sebebi</th>
                  </tr>
                  <tr>
                    <th>İşlem Güvenliği Verisi</th>
                    <td>IP bilginiz</td>
                    <td>
                      Belirtilen bilginiz,{" "}
                      <strong>Urban Care Young Talent Platform, ...,</strong>{" "}
                      internet sitemizi ziyaretiniz vasıtasıyla otomatik
                      yollarla ve{" "}
                      <u>
                        temel hak ve özgürlüklerinize zarar vermemek kaydıyla
                        meşru menfaatlerimizin yerine getirilmesi için işlemenin
                        zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                  <tr>
                    <th>Pazarlama Verisi</th>
                    <td>
                      çerezler vasıtasıyla elde edilen ziyaretlerinize ilişkin
                      bilgiler (Ziyaretçilerin gerçek kişi olduğuna dair Google
                      Analitik kodu, ziyaret sıklığınız, süresi ve ziyaret
                      sayınız, ziyaret ettiğiniz sayfalar)
                    </td>
                    <td>
                      <strong>Urban Care Young Talent Platform, ...,</strong>{" "}
                      internet sitemizi ziyaretiniz vasıtasıyla otomatik
                      yollarla ve <u>açık rızanızın varlığı</u> halinde elde
                      edilmektedir.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>
              Elde Edilen Kişisel Verileriniz Hangi Amaçla ve Hukuki Sebeple
              Kullanılmaktadır?
            </h4>

            <p>
              <strong>Urban Care</strong> tarafından aşağıda yer verilen
              tabloda, aydınlatma yükümlülüğünün yerine getirildiği faaliyet
              kapsamında gerçekleştirilen kişisel veri işleme amaçları
              detaylandırılmıştır. Her bir kişisel veri işleme amacı, amaç
              kapsamında kullanılan kişisel veri kategorileri ve ilgili hukuki
              sebep ile eşleştirilmiştir.
            </p>

            <div className="overflow-x-auto no-scrollbar">
              <table>
                <tbody>
                  <tr>
                    <th>Kişisel Veri İşleme Amacı</th>
                    <th>İlgili Kişisel Veri Kategorisi</th>
                    <th>İşleme Hukuki Sebebi</th>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>İnternet sitesi güvenliğimizin sağlanması,</li>
                        <li>
                          Çerezlere ilişkin tercihlerinizin kaydedilmesi
                          amaçlarıyla
                        </li>
                      </ul>
                    </td>
                    <td>İşlem güvenliği kategorisindeki verileriniz,</td>
                    <td>
                      <u>
                        Hukuki yükümlülüğümüzün yerine getirilmesi için
                        işlemenin zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          <strong>Urban Care</strong> internet sitesi
                          ziyaretçilerinin kullanımlarına yönelik sunucuları
                          yurt dışında bulunan iş ortaklarımızca analitik
                          ölçümler yapılması,
                        </li>
                        <li>
                          Analitik ölçümler vasıtasıyla öğrenilen ziyaretçi
                          hareketlerine yönelik hizmetlerimizin geliştirilmesi,
                        </li>
                        <li>
                          <strong>Urban Care</strong> internet sitesinde
                          gerçekleştirilen ziyaretlerin sayısının
                          artırılabilmesi,
                        </li>
                        <li>
                          Potansiyel müşterilerin <strong>Urban Care</strong>{" "}
                          internet sitesine yönlendirilmesi,
                        </li>
                        <li>
                          Ziyaretçi ve <strong>Urban Care</strong>{" "}
                          müşterilerinin ürün tercihlerinin belirlenmesi ve bu
                          tercihler uyarınca reklam, kampanya ve promosyonlar
                          düzenlenmesi,
                        </li>
                        <li>
                          Reklamcılık faaliyetlerinin yürütülebilmesi
                          amaçlarıyla,
                        </li>
                      </ul>
                    </td>
                    <td>Pazarlama kategorisindeki verileriniz,</td>
                    <td>
                      <u>Açık rızanızın varlığı halinde işlenmektedir.</u>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              Çerezlerin kullanımına ilişkin tercihlerinizi dilediğiniz zaman
              ... adresinden değiştirebilirsiniz.
            </p>

            <h4>Kişisel Verileriniz Başkalarına Aktarılıyor Mu?</h4>

            <p>
              Çerezler ile yürütülen faaliyetlerimiz kapsamında kişisel
              verileriniz; <strong>Urban Care</strong> hizmetlerinin size
              özelleştirilerek geliştirilmesi, pazarlama faaliyetlerinin
              yürütülebilmesi amaçları ile geliştiriciler ve sunucuları yurt
              dışında bulunan hizmet sunucularına{" "}
              <strong>aktarılabilmektedir.</strong>
            </p>

            <h4>KVKK Size Hangi Hakları Veriyor?</h4>

            <p>
              <strong>KVKK</strong> size kişisel verileriniz üzerinde kontrol
              sağlayabilmeniz amacıyla, (a) kişisel verilerinizin işlenip
              işlenmediğini öğrenme; (b) işlenmişse buna ilişkin bilgi talep
              etme, (c) işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme; (d) varsa yurt içinde veya yurt dışında
              kişisel verilerinizin aktarıldığı üçüncü kişileri bilme; (e)
              kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme; (f) kişisel verilerinizin
              silinmesini veya yok edilmesini isteme gibi ve bunlarla sınırlı
              olmamak üzere bir çok hak sağlamaktadır. Bu hakların tamamını ve
              detaylarını <strong>KVKK</strong>'nın 11. maddesinde
              bulabilirsiniz.
            </p>

            <h4>Haklarınızı Nasıl Kullanabilirsiniz?</h4>

            <p>
              Belirtilen haklarınızı kullanmak isterseniz taleplerinizi bize
              yazılı olarak (örneğin{" "}
              <strong>
                ihtarname veya iadeli taahhütlü mektup vasıtasıyla
              </strong>
              ) veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik
              imza, mobil imza ya da bize daha önce bildirdiğiniz ve{" "}
              <strong>sistemimizde</strong> kayıtlı bulunan elektronik posta
              adresinizi kullanmak suretiyle iletebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCookie;
