import React from "react";

const LogoUrbanCare = () => {
  return (
    <svg
      className="w-full block"
      viewBox="0 0 261 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4705 39.6183C35.4705 44.0685 34.2324 47.4245 31.7929 49.6008C29.3228 51.8014 25.5042 52.9201 20.4414 52.9201C15.3785 52.9201 11.5354 51.7892 9.05305 49.5519C6.60131 47.3451 5.35705 43.934 5.35705 39.4165V10.1781H0V39.4165C0 45.8413 1.80816 50.64 5.36931 53.6781C8.6669 56.5451 13.742 58 20.4414 58C27.1408 58 32.1055 56.5696 35.4522 53.7454C39.0194 50.7133 40.8215 45.933 40.8215 39.5449V10.1781H35.4583V39.6183H35.4705Z"
        fill="#DF1683"
      />
      <path
        d="M35.4705 39.6183C35.4705 44.0685 34.2324 47.4245 31.7929 49.6008C29.3228 51.8014 25.5042 52.9201 20.4414 52.9201C15.3785 52.9201 11.5354 51.7892 9.05305 49.5519C6.60131 47.3451 5.35705 43.934 5.35705 39.4165V10.1781H0V39.4165C0 45.8413 1.80816 50.64 5.36931 53.6781C8.6669 56.5451 13.742 58 20.4414 58C27.1408 58 32.1055 56.5696 35.4522 53.7454C39.0194 50.7133 40.8215 45.933 40.8215 39.5449V10.1781H35.4583V39.6183H35.4705Z"
        fill="black"
      />
      <path
        d="M87.852 23.0704C87.852 14.5183 82.1456 10.1781 70.8982 10.1781H47.9683V57.5293H53.3315V37.2342H70.2914C74.2326 37.2342 77.193 37.925 79.0993 39.282C80.9319 40.5841 81.8207 42.5158 81.8207 45.1811V57.5293H87.19V45.0466C87.19 40.144 85.2593 36.5679 81.4407 34.4039C85.7006 32.0871 87.8581 28.2787 87.8581 23.0704H87.852ZM82.4888 23.2721C82.4888 26.243 81.22 28.511 78.7192 30.0209C76.5617 31.3474 73.6135 32.0198 69.9543 32.0198H53.3253V15.2641H71.0269C74.9436 15.2641 77.8857 15.9549 79.7796 17.3242C81.6 18.6385 82.4827 20.5885 82.4827 23.2782L82.4888 23.2721Z"
        fill="#DF1683"
      />
      <path
        d="M87.852 23.0704C87.852 14.5183 82.1456 10.1781 70.8982 10.1781H47.9683V57.5293H53.3315V37.2342H70.2914C74.2326 37.2342 77.193 37.925 79.0993 39.282C80.9319 40.5841 81.8207 42.5158 81.8207 45.1811V57.5293H87.19V45.0466C87.19 40.144 85.2593 36.5679 81.4407 34.4039C85.7006 32.0871 87.8581 28.2787 87.8581 23.0704H87.852ZM82.4888 23.2721C82.4888 26.243 81.22 28.511 78.7192 30.0209C76.5617 31.3474 73.6135 32.0198 69.9543 32.0198H53.3253V15.2641H71.0269C74.9436 15.2641 77.8857 15.9549 79.7796 17.3242C81.6 18.6385 82.4827 20.5885 82.4827 23.2782L82.4888 23.2721Z"
        fill="black"
      />
      <path
        d="M127.852 32.57C131.757 30.3204 133.736 27.1294 133.736 23.0704C133.736 18.7302 132.008 15.368 128.6 13.0879C125.713 11.1562 122.005 10.1781 117.579 10.1781H93.9078V57.5293H116.715C121.649 57.5293 125.793 56.5879 129.017 54.7234C133.129 52.3577 135.213 48.7205 135.213 43.9035C135.213 38.4568 132.737 34.6545 127.846 32.57H127.852ZM128.373 22.7403C128.373 25.3078 127.264 27.2823 125.076 28.6088C123.083 29.8069 120.497 30.4121 117.377 30.4121H99.2648V15.2641H117.438C120.472 15.2641 123.004 15.8265 124.953 16.9452C127.282 18.2656 128.367 20.1056 128.367 22.7403H128.373ZM99.271 52.4555V35.4981H117.242C120.828 35.4981 123.752 36.0911 125.921 37.2525C128.606 38.6891 129.912 40.8898 129.912 43.9768C129.912 46.8499 128.686 48.965 126.148 50.4444C123.948 51.7709 121.042 52.4494 117.512 52.4494H99.2648L99.271 52.4555Z"
        fill="#DF1683"
      />
      <path
        d="M127.852 32.57C131.757 30.3204 133.736 27.1294 133.736 23.0704C133.736 18.7302 132.008 15.368 128.6 13.0879C125.713 11.1562 122.005 10.1781 117.579 10.1781H93.9078V57.5293H116.715C121.649 57.5293 125.793 56.5879 129.017 54.7234C133.129 52.3577 135.213 48.7205 135.213 43.9035C135.213 38.4568 132.737 34.6545 127.846 32.57H127.852ZM128.373 22.7403C128.373 25.3078 127.264 27.2823 125.076 28.6088C123.083 29.8069 120.497 30.4121 117.377 30.4121H99.2648V15.2641H117.438C120.472 15.2641 123.004 15.8265 124.953 16.9452C127.282 18.2656 128.367 20.1056 128.367 22.7403H128.373ZM99.271 52.4555V35.4981H117.242C120.828 35.4981 123.752 36.0911 125.921 37.2525C128.606 38.6891 129.912 40.8898 129.912 43.9768C129.912 46.8499 128.686 48.965 126.148 50.4444C123.948 51.7709 121.042 52.4494 117.512 52.4494H99.2648L99.271 52.4555Z"
        fill="black"
      />
      <path
        d="M163.01 10.1781H158.603L136.341 57.5293H142.373L149.029 43.2616H172.1L178.615 57.5293H184.5L163.163 10.5388L162.998 10.1781H163.01ZM151.432 38.1145L160.828 18.394L169.82 38.1145H151.432Z"
        fill="#DF1683"
      />
      <path
        d="M163.01 10.1781H158.603L136.341 57.5293H142.373L149.029 43.2616H172.1L178.615 57.5293H184.5L163.163 10.5388L162.998 10.1781H163.01ZM151.432 38.1145L160.828 18.394L169.82 38.1145H151.432Z"
        fill="black"
      />
      <path
        d="M223.623 27.6429V48.5249C220.27 44.3925 215.3 38.4018 208.809 30.6933C201.735 22.2268 196.176 15.4475 192.302 10.551L192.014 10.1842H187.019V57.5354H192.376V18.9503L223.997 57.5293H228.986V27.6429H223.623Z"
        fill="#DF1683"
      />
      <path
        d="M223.623 27.6429V48.5249C220.27 44.3925 215.3 38.4018 208.809 30.6933C201.735 22.2268 196.176 15.4475 192.302 10.551L192.014 10.1842H187.019V57.5354H192.376V18.9503L223.997 57.5293H228.986V27.6429H223.623Z"
        fill="black"
      />
      <path
        d="M210.12 2.60413C212.958 2.60413 215.024 3.52108 216.274 5.33052L216.63 5.85013L218.947 4.41358L218.542 3.83895C216.74 1.28984 213.908 0 210.12 0C206.884 0 204.267 0.935287 202.342 2.78141C200.405 4.63364 199.425 7.15219 199.425 10.2576C199.425 13.363 200.381 15.747 202.262 17.5504C204.138 19.3415 206.688 20.2462 209.857 20.2462C213.491 20.2462 216.335 18.9564 218.309 16.4073L218.75 15.8326L216.415 14.3472L216.047 14.8362C214.638 16.6946 212.547 17.636 209.832 17.636C207.442 17.636 205.542 16.9574 204.181 15.6126C202.832 14.2677 202.146 12.4277 202.146 10.1414C202.146 7.85519 202.863 5.97239 204.279 4.63364C205.707 3.28267 207.675 2.59802 210.12 2.59802V2.60413Z"
        fill="#DF1683"
      />
      <path
        d="M231.947 6.60814C230.709 5.58116 229.103 5.06155 227.154 5.06155C224.8 5.06155 222.918 5.81345 221.558 7.2989C220.283 8.69878 219.633 10.5327 219.633 12.7517C219.633 14.9707 220.27 16.719 221.533 18.1189C222.882 19.6043 224.739 20.3624 227.05 20.3624C229.042 20.3624 230.684 19.8183 231.953 18.7424V20.0812H234.674V5.11046H231.953V6.60814H231.947ZM222.33 12.7517C222.33 11.2723 222.728 10.0559 223.513 9.1328C224.377 8.1425 225.523 7.66568 227.013 7.66568C228.619 7.66568 229.869 8.13027 230.721 9.04111C231.536 9.9336 231.947 11.1868 231.947 12.7517C231.947 14.1882 231.493 15.3986 230.598 16.3461C229.704 17.2936 228.533 17.7521 227.013 17.7521C225.493 17.7521 224.322 17.2753 223.507 16.3339C222.71 15.4292 222.324 14.2616 222.324 12.7517H222.33Z"
        fill="#DF1683"
      />
      <path
        d="M239.707 5.35498H236.979V20.0567H239.707V14.0354C239.707 12.2015 240.074 10.6916 240.804 9.5546C241.631 8.2831 242.967 7.66568 244.886 7.66568H245.548V5.06155H244.886C242.759 5.06155 241.018 5.58727 239.707 6.63259V5.35498Z"
        fill="#DF1683"
      />
      <path
        d="M261 12.2321C261 10.062 260.295 8.30143 258.904 7.01159C257.519 5.71564 255.661 5.05544 253.393 5.05544C251.126 5.05544 249.256 5.77677 247.846 7.2011C246.467 8.61931 245.762 10.4838 245.762 12.7517C245.762 15.0196 246.381 16.878 247.607 18.2045C248.919 19.6349 250.819 20.3624 253.252 20.3624C254.914 20.3624 256.372 20.02 257.58 19.3476C259.002 18.5774 260.025 17.2325 260.614 15.3558L260.718 15.0318H257.88C257.132 16.8596 255.643 17.7582 253.32 17.7582C250.408 17.7582 248.882 16.5234 248.527 13.8826L261 13.8581V12.2321ZM248.606 11.2662C248.833 10.2454 249.287 9.43845 249.992 8.81492C250.887 8.03858 252.002 7.65957 253.393 7.65957C254.785 7.65957 255.9 8.04469 256.74 8.80881C257.439 9.42622 257.88 10.2515 258.058 11.2601H248.606V11.2662Z"
        fill="#DF1683"
      />
    </svg>
  );
};

export default LogoUrbanCare;
