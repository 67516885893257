import React, { ButtonHTMLAttributes, FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { LocationStates } from "routers/types";

export interface ButtonProps {
  className?: string;
  translate?: string;
  sizeClass?: string;
  fontSize?: string;
  //
  icon?: boolean;
  //
  loading?: boolean;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  href?: keyof LocationStates | "#" | LinkProps["to"];
  targetBlank?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  className = "text-white",
  translate = "",
  sizeClass = "py-4 px-6",
  fontSize = "text-base font-bold leading-tight",
  icon = false,
  disabled = false,
  href,
  children,
  targetBlank,
  type,
  loading,
  onClick = () => {},
}) => {
  const CLASSES = `button relative h-auto inline-flex items-center justify-center rounded-lg transition-all ${fontSize} ${sizeClass} ${translate} ${className}`;

  const _renderLoading = () => {
    return (
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="3"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  if (!!href) {
    return (
      <Link
        to={href}
        target={targetBlank ? "_blank" : undefined}
        className={`${CLASSES} `}
        onClick={onClick}
        rel="noopener noreferrer"
      >
        {children || `Link`}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled || loading}
      className={`${CLASSES}`}
      onClick={onClick}
      type={type}
    >
      {loading && _renderLoading()}
      {children || `Button`}
      {icon && (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7929 5.29289C13.1834 4.90237 13.8166 4.90237 14.2071 5.29289L18.2071 9.29289C18.5976 9.68342 18.5976 10.3166 18.2071 10.7071L14.2071 14.7071C13.8166 15.0976 13.1834 15.0976 12.7929 14.7071C12.4024 14.3166 12.4024 13.6834 12.7929 13.2929L15.0858 11H3.5C2.94772 11 2.5 10.5523 2.5 10C2.5 9.44772 2.94772 9 3.5 9H15.0858L12.7929 6.70711C12.4024 6.31658 12.4024 5.68342 12.7929 5.29289Z"
            fill="white"
          />
        </svg>
      )}
    </button>
  );
};

export default Button;
