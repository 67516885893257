import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome/PageHome";
import PageAbout from "containers/PageAbout/PageAbout";
import PageProgramDetail from "containers/PageProgramDetail/PageProgramDetail";
import FooterAlt from "shared/Footer/FooterAlt";
import Page404 from "containers/Page404/Page404";
import PageGDPR from "containers/PageGDPR/PageGDPR";
import PageCookie from "containers/PageGDPR/PageCookie";
import PageMembership from "containers/PageGDPR/PageMembership";
import PageApplication from "containers/PageApplication/PageApplication";
//import PageForm from "containers/PageForm/PageForm";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  //
  { path: "/hakkimizda", component: PageAbout },
  { path: "/program-detayi", component: PageProgramDetail },
  { path: "/basvur", component: PageApplication },
  // kvkk
  { path: "/uyelik-sozlesmesi", component: PageMembership },
  { path: "/kvkk", component: PageGDPR },
  { path: "/cerezlerin-islenmesi", component: PageCookie },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />
      <ScrollToTop>
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </ScrollToTop>

      <FooterAlt />
    </BrowserRouter>
  );
};

export default MyRoutes;
