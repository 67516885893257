import React, { FC, useEffect } from "react";
import SectionApplicationProcess from "components/SectionApplicationProcess/SectionApplicationProcess";
import { Helmet } from "react-helmet";

export interface PageApplicationProps {
  className?: string;
}

const PageApplication: FC<PageApplicationProps> = ({ className = "" }) => {
  useEffect(() => {
    const handlePageReload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handlePageReload);

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []);

  return (
    <div className={`page-about ${className}`}>
      <Helmet>
        <title>URBAN Care Young Talent Platform | Başvur</title>
      </Helmet>

      <div className="relative py-14 md:pt-24 ">
        <SectionApplicationProcess />
      </div>

      <div className={`container py-14 md:pb-24`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <div className="gdpr-wrapper">
            <p>
              <strong>Hak kazanan katılımcıların</strong> eğitime katılım
              sağlaması için <strong>gerekli vize işlemleri</strong> de platform
              aracılığıyla sağlanacaktır.{" "}
            </p>
            <p>
              Başvuru yapmayı planlayan katılımcıların belirlenen dans
              kategorilerinde halihazırda kabul aldığı bir yaz okulu olması
              durumunda da burs fonuna başvurma imkanı sağlanmaktadır.{" "}
              <strong>Çağdaş – Modern, Sokak Dansı ve Bale</strong> dans
              kategorilerinin tüm alt branşları projeye dahildir.{" "}
            </p>
            <p>
              <strong>
                “Gençlerin öncülüğünde, yetişkinlerin rehberliğinde”
              </strong>{" "}
              prensibiyle hareket eden ve gençlere destek vererek toplumsal
              sorunlara çözüm üreten projeler gerçekleştirmeleri için alan
              yaratan <strong>Toplum Gönülleri Vakfı (TOG)</strong> iş
              birliğiyle gerçekleştirilen proje, Sanat Yönetmeni, Koreograf,
              Eğitmen Beyhan Murphy’nin başkanlığını yaptığı danışma kurulu ve
              sanatçı, dans ve hareket terapisti <strong>Ekin Bernay</strong>’ın
              koordinatörlüğünde çalışmalarını sürdürmektedir.
            </p>
            <p>
              İlerleyen dönemde <strong>Ekin Bernay</strong>, seçici kurul
              üyeleri ve eğitim imkanı sağlanan gençlerle birlikte, Anadolu’nun
              farklı köşelerinde ve özellikle afet bölgesinde yer alan{" "}
              <strong>TOG Destek Merkezleri</strong>’nde gönüllü olarak{" "}
              <strong>URBAN Care Dans Atölyeleri</strong> gerçekleştirilmesi
              planlanmaktadır.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageApplication;
