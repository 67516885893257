import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import tog from "images/tog.svg";
import LogoTog from "shared/Logo/LogoTog";
import Navigation from "shared/Navigation/Navigation";
import MenuBar from "shared/MenuBar/MenuBar";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  return (
    <div className={`main-navigation relative z-10 ${className}`}>
      <div className="nav-wrapper md:container relative flex justify-between items-center px-4 py-6">
        <div className="flex justify-start flex-1 items-center space-x-6">
          <span
            className="flex"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <Logo className="w-[141px]" />
          </span>
          <a
            href="https://www.tog.org.tr/"
            title="Toplum Gönüllüleri Vakfı (TOG)"
            target="_blank"
            className="border-l border-[#101828] pl-6 w-[95px]"
          >
            <LogoTog />
          </a>
        </div>

        <div className="flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none">
          <div className="hidden md:flex items-center">
            <Navigation />
            <ButtonSecondary
              onClick={() => (window.location.href = "/basvur")}
              className="!py-3 !px-6 mr-4 lg:mr-0 md:ml-6"
            >
              BAŞVURU SÜRECİ
            </ButtonSecondary>
          </div>
          <div className="flex lg:hidden items-center">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
