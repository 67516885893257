import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageMembershipProps {
  className?: string;
}

const PageMembership: FC<PageMembershipProps> = ({ className = "" }) => {
  return (
    <div className={`page-cookie ${className}`}>
      <Helmet>
        <title>Urban Care Young Talent Platform | Üyelik Sözleşmesi</title>
      </Helmet>

      <div className={`container py-14 md:py-24 ${className}`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <div
            className={`section-heading relative flex flex-col sm:flex-row justify-between`}
          >
            <div className="text-center w-full max-w-[60rem] mx-auto">
              <div className="relative p-0">
                <h2 className={`default-heading mb-0 items-center`}>
                  URBAN CARE YOUNG TALENT PLATFORM BAŞVURU SÜREÇLERİ KAPSAMINDA
                  KİŞİSEL VERİLERİN İŞLENMESİNE YÖNELİK AYDINLATMA METNİ
                </h2>
                <div
                  className={`mt-3 !max-w-[220px] md:max-w-[unset] w-full h-[2px] left-0 -bottom-3 m-auto bg-main`}
                />
              </div>
            </div>
          </div>
          <div className="gdpr-wrapper">
            <p>
              <strong>Selen Kozmetik Anonim Şirketi (“Selen Kozmetik”</strong>{" "}
              veya <strong>“Urban Care”)</strong> olarak kişisel verilerinizin
              korunmasına değer veriyor, dolayısıyla sizin de aynı hassasiyeti
              göstermenizi bekliyoruz. Bu sebeple hangi kişisel verilerinizi ne
              amaçla kullandığımızı detaylarıyla anlatan bu metni okumanız,
              bunların idari ve teknik güvenliğinden{" "}
              <strong>“Veri Sorumlusu”</strong> sıfatıyla sorumlu olan{" "}
              <strong>Urban Care'in</strong> yükümlülüklerini net bir şekilde
              anladığınızı göstermeniz bizim için çok önemlidir.
            </p>

            <h4>Neden Bu Metni Okuyorum?</h4>

            <p>
              <strong>7 Nisan 2016</strong> tarihinde yürürlüğe giren{" "}
              <strong>
                6698 sayılı Kişisel Verilerin Korunması Kanununun (“KVKK”) 10.
                maddesi
              </strong>
              , kişisel verileri işlenen (kullanılan) kişilerin{" "}
              <strong>(KVKK'da ilgili kişi olarak ifade edilmektedir)</strong>{" "}
              bu verileri kullanan <strong>“Veri Sorumlusu”</strong> tarafından
              bilgilendirilmesini zorunlu kılmaktadır. Bizler de{" "}
              <strong>Veri Sorumlusu</strong> olarak{" "}
              <u>
                sizler hakkında işleyeceğimiz kişisel verilerle ilgili
                bilgilendirildiğinizi ispat etmek durumundayız.
              </u>
            </p>

            <h4>
              Kullanılan Kişisel Verileriniz Neler Olup Hangi Yollarla Elde
              Edilmektedir?
            </h4>

            <p>
              <strong>Urban Care</strong> olarak aydınlatma metni kapsamındaki
              kişisel verilerinizi, internet sitemiz üzerinden üyelik
              oluşturmanızla, burs başvurunuz ve/veya etkinlik başvurunuz
              çerçevesinde hizmetlerimizi kullanmanızla birlikte dijital
              yollarla{" "}
              <strong>
                (KVKK'da “otomatik olan” yöntem şeklinde ifade edilmektedir.)
              </strong>{" "}
              elde etmekteyiz. Elde edilen kişisel verilerinizin neler olduğu ve
              elde etmemizin hukuki sebebi aşağıda açıklanmıştır:
            </p>

            <div className="overflow-x-auto no-scrollbar">
              <table>
                <tbody>
                  <tr>
                    <th>Veri Kategorisi</th>
                    <th>İşlenen Kişisel Verileriniz</th>
                    <th>Elde Etmenin Hukuki Sebebi</th>
                  </tr>
                  <tr>
                    <th>Kimlik Verisi</th>
                    <td>Adınız ve soyadınız</td>
                    <td>
                      Belirtilen bilgileriniz, üyelik sözleşmesinin kurulması,
                      başvuru formunu doldurmanız vasıtasıyla{" "}
                      <u>
                        sözleşmenin kurulması ve yerine getirilmesiyle doğrudan
                        doğruya ilgili olan, sözleşmenin taraflarına ait kişisel
                        verilerin işlenmesinin gerekli olması
                      </u>{" "}
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                  <tr>
                    <th>İletişim Verisi</th>
                    <td>Telefon numaranız, e-posta adresiniz, adresiniz</td>
                    <td>
                      Belirtilen bilgileriniz, üyelik sözleşmesinin kurulması,
                      başvuru formunu doldurmanız vasıtasıyla{" "}
                      <u>
                        sözleşmenin kurulması ve yerine getirilmesiyle doğrudan
                        doğruya ilgili olan, sözleşmenin taraflarına ait kişisel
                        verilerin işlenmesinin gerekli olması
                      </u>{" "}
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                  <tr>
                    <th>Hukuki İşlem Verisi</th>
                    <td>
                      Aydınlatma Metnini okuyup anladığınız yönünde kayıt,
                      üyelik sözleşmesini okuyup kabul ettiğiniz yönünde kayıt
                    </td>
                    <td>
                      Belirtilen bilgileriniz, üyelik sözleşmesini kabul
                      ettiğiniz/başvuruda bulunduğunuz ve aydınlatma metnini
                      okuyup anladığınız yönünde kaydı içeren kutucuğun
                      işaretlenmesi vasıtasıyla{" "}
                      <u>
                        hukuki yükümlülüğümüzün yerine getirilmesi için zorunlu
                        olması
                      </u>{" "}
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                  <tr>
                    <th>İşlem Güvenliği Verisi</th>
                    <td>
                      Şifre bilgileriniz, e-posta ve cep telefonu doğrulama
                      bilgisi, IP adresi bilginiz, cihaz türünüz ve işletim
                      sistemi bilginiz, teknik hizmetlere ilişkin hata kayıtları
                    </td>
                    <td>
                      Belirtilen bilgileriniz, ... internet sitemizi ziyaret
                      etmeniz vasıtasıyla{" "}
                      <u>
                        hukuki yükümlülüğümüzün yerine getirilmesi için zorunlu
                        olması
                      </u>{" "}
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>
              Elde Edilen Kişisel Verileriniz Hangi Amaçla ve Hukuki Sebeple
              Kullanılmaktadır?
            </h4>

            <p>
              <strong>Urban Care</strong> tarafından aşağıda yer verilen
              tabloda, aydınlatma yükümlülüğünün yerine getirildiği faaliyet
              kapsamında gerçekleştirilen kişisel veri işleme amaçları
              detaylandırılmıştır. Her bir kişisel veri işleme amacı, amaç
              kapsamında kullanılan kişisel veri kategorileri ve ilgili hukuki
              sebep ile eşleştirilmiştir.
            </p>

            <div className=" overflow-x-auto no-scrollbar">
              <table>
                <tbody>
                  <tr>
                    <th>Kişisel Veri İşleme Amacı</th>
                    <th>İlgili Kişisel Veri Kategorisi</th>
                    <th>İşleme Hukuki Sebebi</th>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Başvuru formunuzun değerlendirilmesi amacıyla,</li>
                      </ul>
                    </td>
                    <td className="w-[35%]">
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>İletişim,</li>
                        <li>Hukuki işlem</li>
                        <li>Mesleki Deneyim</li>
                        <li>Özlük</li>
                        <li>Adres Bilgisi</li>
                        <li>Demografik Bilgiler</li>
                        <li>Görsel ve İşitsel Bilgiler</li>
                        <li>Sağlık Bilgileri</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        Sözleşmenin kurulması ve yerine getirilmesiyle doğrudan
                        doğruya ilgili olan, sözleşmenin taraflarına ait kişisel
                        verilerin işlenmesinin gerekli olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Üyelik sözleşmenizin kurulması, profilinizin
                          oluşturulması Başvurunuzun işleme alınması amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>İletişim,</li>
                        <li>Müşteri İşlem</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        Sözleşmenin kurulması ve yerine getirilmesiyle doğrudan
                        doğruya ilgili olan, sözleşmenin taraflarına ait kişisel
                        verilerin işlenmesinin gerekli olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Üyeliğinize ve başvurunuza ilişkin bilgilendirme
                          iletişimlerinin sağlanması amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>İletişim,</li>
                        <li>Müşteri İşlem</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        Sözleşmenin kurulması ve yerine getirilmesiyle doğrudan
                        doğruya ilgili olan, sözleşmenin taraflarına ait kişisel
                        verilerin işlenmesinin gerekli olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          İletişim bilgilerinizin güncelliğinin sağlanması ve
                          teyit edilmesi amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>İletişim,</li>
                        <li>İşlem Güvenliği</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        İşlemenin hukuki yükümlülüğümüzün yerine getirilebilmesi
                        için zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Sistem güvenliğinin sağlanması, denetlenmesi ve
                          geliştirilmesi, Teknik altyapının sağlanması,
                          geliştirmelerinin ve güncellemelerinin sağlanması,
                          Sistemsel sorunların tespit edilmesi ve giderilmesi
                          adına çalışmaların yürütülmesi amaçlarıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>İşlem güvenliği</li>
                      </ul>

                      <p>kategorisindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        İşlemenin hukuki yükümlülüğümüzün yerine getirilebilmesi
                        için zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Şifre yenileme işlemlerinin yürütülmesi amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>İletişim,</li>
                        <li>İşlem Güvenliği,</li>
                      </ul>

                      <p>kategorilerindeki verileriniz</p>
                    </td>
                    <td>
                      <u>
                        İşlemenin hukuki yükümlülüğümüzün yerine getirilebilmesi
                        için zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Üyelik hesabınıza ilişkin hukuki onay, izin, açık rıza
                          ve/veya ret kayıtlarınızın tutulması amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>Hukuki İşlem</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        İşlemenin hukuki yükümlülüğümüzün yerine getirilebilmesi
                        için zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Aydınlatma yükümlülüğünün yerine getirildiğinin
                          kaydedilmesi amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>Hukuki İşlem</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        İşlemenin hukuki yükümlülüğümüzün yerine getirilebilmesi
                        için zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          Hukuk işlerinin takip edilmesi ve yürütülmesi
                          amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>İletişim,</li>
                        <li>Hukuki İşlem,</li>
                        <li>Müşteri İşlem</li>
                      </ul>

                      <p>kategorilerindeki verileriniz,</p>
                    </td>
                    <td>
                      <u>
                        Bir hakkın korunması, kullanılması ve tesisi için
                        işlemenin zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>
                          <strong>Urban Care</strong> hizmetlerinin kötüye
                          kullanımının engellenmesi adına denetimlerin
                          yürütülmesi amacıyla,
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul className="!list-none !pl-0">
                        <li>Kimlik,</li>
                        <li>İletişim,</li>
                        <li>Finans,</li>
                        <li>Hukuki İşlem,</li>
                        <li>Müşteri İşlem,</li>
                        <li>Pazarlama</li>
                      </ul>

                      <p>kategorilerindeki verileriniz</p>
                    </td>
                    <td>
                      <u>
                        Bir hakkın korunması, kullanılması ve tesisi için
                        işlemenin zorunlu olması
                      </u>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>Kişisel Verileriniz Başkalarına Aktarılıyor Mu?</h4>
            <p>
              Evet, kişisel verilerinizi özetle iş ortaklarımıza, resmî
              kurumlara, denetçilerimize, avukatlarımıza, mali müşavirlerimize
              aktarmak durumunda kalabiliyoruz. Ancak aktarımı yalnızca belirli
              amaçlar çerçevesinde ve bilginiz dahilinde yapıyoruz. Dolayısıyla
              kişisel verileriniz;
            </p>

            <ul>
              <li>
                İnternet sitemiz aracılığıyla size hizmetlerimizi sunmak,
                sunduğumuz hizmetlerin kalitesini arttırmak, size özelleştirerek
                geliştirmek, teknik altyapıyı sağlamak ile destek ve kontrolleri
                yürütülmek amacıyla bu konuda anlaşmalı olduğumuz
                tedarikçilerimize ve hizmet sağlayıcılarımıza,
              </li>
              <li>
                Yetkili kurum ve kuruluşların talep etmesi halinde mevcut kanuni
                yükümlülüklerimizin yerine getirilmesi amacıyla; düzenleyici ve
                denetleyici kurumlara ve kanunlarda açıkça kişisel verileri
                talep etmeye yetkili olan kamu kurum veya kuruluşlarına,
              </li>
              <li>
                Hukuk işlerinin takibi amacıyla birlikte çalışmakta olduğumuz
                yasal takip süreçleri ile ilgili zorunlu kişilere ve
                danışmanlarımıza,
              </li>
              <li>
                İş süreçlerinin takibi, devamlılığının sağlanması, denetimi ve
                raporlama süreçlerinin yürütülebilmesi amacıyla grup
                şirketlerimize
              </li>
            </ul>
            <p>aktarılmaktadır.</p>

            <h4>KVKK Size Hangi Hakları Veriyor?</h4>
            <p>
              <strong>KVKK</strong> size kişisel verileriniz üzerinde kontrol
              sağlayabilmeniz amacıyla, (a) kişisel verilerinizin işlenip
              işlenmediğini öğrenme; (b) işlenmişse buna ilişkin bilgi talep
              etme, (c) işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme; (d) varsa yurt içinde veya yurt dışında
              kişisel verilerinizin aktarıldığı üçüncü kişileri bilme; (e)
              kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme; (f) kişisel verilerinizin
              silinmesini veya yok edilmesini isteme gibi ve bunlarla sınırlı
              olmamak üzere bir çok hak sağlamaktadır. Bu hakların tamamını ve
              detaylarını <strong>KVKK</strong>'nın 11. maddesinde
              bulabilirsiniz.
            </p>

            <h4>Haklarınızı Nasıl Kullanabilirsiniz?</h4>
            <p>
              Belirtilen haklarınızı kullanmak isterseniz taleplerinizi bize
              yazılı olarak (örneğin{" "}
              <strong>
                ihtarname veya iadeli taahhütlü mektup vasıtasıyla
              </strong>
              ) veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik
              imza, mobil imza ya da bize daha önce bildirdiğiniz ve{" "}
              <strong>sistemimizde</strong> kayıtlı bulunan elektronik posta
              adresinizi kullanmak suretiyle iletebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageMembership;
