import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imageLeft from "images/hero/hero-left.svg";
import imageRight from "images/hero/hero-right.svg";
import LogoUrbanCare from "shared/Logo/LogoUrbanCare";
import LogoTog from "shared/Logo/LogoTog";
import NcImage from "shared/NcImage/NcImage";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";
import ReactPlayer from "react-player";
import thumb from "images/video_thumbnail.jpg";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [isFirstClick, setIsFirstClick] = useState(false);

  const handlePlayPause = () => {
    setIsFirstClick(true);
    setIsPlay(!isPlay);
  };

  const renderMainVideo = () => {
    return (
      <div
        className="group relative max-w-[640px] m-auto border-[3px] border-main border-opacity-20 rounded-3xl overflow-hidden transition-all"
        title="Hayallerine Giden Yolda Destek Olmayı Hedefliyoruz"
      >
        <div
          className="w-full h-full z-[1] absolute left-0 top-0"
          onClick={handlePlayPause}
        />
        <ReactPlayer
          url="https://res.cloudinary.com/denaoslji/video/upload/v1706198215/web-banner.mp4"
          width="100%"
          height="auto"
          playsinline
          playing={isPlay}
        />
        {!isPlay && (
          <>
            <div
              onClick={handlePlayPause}
              className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
            >
              <NcPlayIcon />
            </div>

            {!isFirstClick ? (
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
                src={thumb}
                title="Hayallerine Giden Yolda Destek Olmayı Hedefliyoruz"
                alt="Hayallerine Giden Yolda Destek Olmayı Hedefliyoruz"
              />
            ) : (
              <div className="w-full h-full z-[1] absolute bg-black opacity-5 left-0 top-0" />
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={`section-hero flex flex-col relative ${className}`}>
      <div className="flex h-full">
        <div className="flex flex-col items-center gap-6 m-auto pt-[72px] lg:pt-28 pb-12 justify-between h-full z-[1]">
          <div className="flex flex-col gap-6 md:gap-[84px] items-center m-auto lg:m-[unset]">
            <h1 className="text-[45px] leading-none md:text-[84px] tracking-wider font-black uppercase text-center">
              <span className="text-main">DANSTA</span>
              <br /> FIRSAT EŞİTLİĞİ
            </h1>
            <div className="">
              <h2 className="text-main text-[40px] leading-none md:text-[58px] font-extrabold text-center">
                Kazananlar Açıklandı!
              </h2>
              <p className="mt-3 text-2xl text-center font-bold">
                Değerlendirmeler sonucunda projenin ilk dönem kazananları ile
                iletişime geçilmiştir! <br className="hidden md:block" />
                Programın ilerleyen aşamalarıyla ilgili olarak en kısa sürede{" "}
                <br className="hidden lg:block" />
                bilgilendirme yapılacaktır. Takipte kalın!
              </p>
            </div>
          </div>

          <div className="flex gap-6 items-center">
            <a
              href="https://urbancare.com.tr/"
              title="URBAN Care"
              target="_blank"
              className="w-[155px] lg:w-[210px]"
            >
              <LogoUrbanCare />
            </a>
            <a
              href="https://www.tog.org.tr/"
              title="Toplum Gönüllüleri Vakfı (TOG)"
              target="_blank"
              className="border-l border-[#101828] pl-6 w-[75px] lg:w-[95px]"
            >
              <LogoTog />
            </a>
          </div>
        </div>
        <div className="hero-imgs absolute flex  items-center justify-center w-full h-full -z-[0]">
          <img
            className="absolute mt-6 md:mt-0 w-[20%] lg:w-[unset] md:bottom-20 top-0 md:top-[unset] -left-0 lg:-left-24 -right-0 xl:left-6"
            src={imageLeft}
            alt="Dansta Fırsat Eşitliği"
          />
          <img
            className="absolute mt-7 md:mt-0 w-[35%] xl:w-[unset] md:bottom-20 top-0 md:top-[unset] lg:top-[111px] lg:-right-24 -right-0 xl:right-6"
            src={imageRight}
            alt="Dansta Fırsat Eşitliği"
          />
        </div>
        <div className="nav-wrapper" />
      </div>
    </div>
  );
};

export default SectionHero;
