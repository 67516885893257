import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsIconProps {
  className?: string;
  itemClass?: string;
  rounded?: boolean;
  light?: boolean;
  socials?: SocialType[];
}

const socialLinks: SocialType[] = [
  {
    name: "Instagram",
    icon: "instagram",
    href: "https://www.instagram.com/urbancaretr/",
  },
  {
    name: "Facebook",
    icon: "facebook",
    href: "https://tr-tr.facebook.com/UrbanCareTurkiye/",
  },
  {
    name: "X",
    icon: "x",
    href: "https://twitter.com/UrbanCareTR",
  },
  {
    name: "Youtube",
    icon: "youtube",
    href: "https://www.youtube.com/user/URBANCareTR",
  },
  {
    name: "Tiktok",
    icon: "tiktok",
    href: "https://www.tiktok.com/@urbancaretr?is_from_webapp=1&sender_device=pc",
  },
];

const SocialsIcon: FC<SocialsIconProps> = ({
  className = "",
  itemClass = "block",
  rounded = false,
  light = false,
  socials = socialLinks,
}) => {
  return (
    <nav
      className={`flex space-x-5  overflow-x-auto sm:overflow-x-visible no-scrollbar ${className}`}
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`hover:scale-110 transition-all duration-200 flex-shrink-0 ${itemClass} 
          ${
            rounded
              ? "flex border border-cGrey2 flex-shrink-0 rounded-full hover:scale-110 transition-all duration-200"
              : ""
          }`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img
            src={
              light
                ? require("../../images/icons/" + item.icon + "_hover.png")
                : require("../../images/icons/" + item.icon + ".png")
            }
            alt={`Young Talent Platform ` + item.name}
            className="w-[46px] sm:w-[56px] flex-shrink-0"
          />
        </a>
      ))}
    </nav>
  );
};

export default SocialsIcon;
