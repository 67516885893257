import React from "react";
import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION } from "data/navigation";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { XMarkIcon } from "@heroicons/react/20/solid";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION,
  onClickClose,
}) => {
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              end
              to={i.href || "/"}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${
                  isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 pr-3 ${!i.children ? "block w-full" : ""}`}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex-1 flex"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="py-2.5 flex justify-end flex-1"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={item.id} as="li" className="">
        <NavLink
          end
          className={
            ({ isActive }) =>
              `flex w-full px-4 font-bold uppercase tracking-wide text-base hover:bg-neutral-100 rounded-lg`
            // ${
            //   isActive ? "text-main" : ""
            // }
          }
          to={item.href || "/"}
          onClick={(e) => {
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }, 100); // 1000 milisaniye (1 saniye) bekleme süresi
          }}
        >
          <span
            className={`py-2.5 pr-3 ${!item.children ? "block w-full" : ""}`}
          >
            {item.name}
          </span>
          {item.children && (
            <span className="flex-1 flex" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button
                as="span"
                className="py-2.5 flex items-center justify-end flex-1 "
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  return (
    <div className="overflow-y-auto w-full h-screen transition transform shadow-lg ring-1 bg-white divide-y-2 divide-neutral-100">
      <div className="py-6 px-4 flex justify-between">
        <Logo className="w-[141px]" />
        <span className="flex items-center justify-center rounded-lg">
          <XMarkIcon className="w-10 h-10 text-black" onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
      <div className="flex items-center justify-between py-6 px-5">
        <a className="inline-block" href="/basvur" rel="noopener noreferrer">
          <ButtonSecondary>ŞİMDİ BAŞVUR</ButtonSecondary>
        </a>
      </div>
    </div>
  );
};

export default NavMobile;
