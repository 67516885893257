import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import faq from "images/faq.svg";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export interface SectionFaqProps {
  className?: string;
}

const data = [
  {
    id: "01",
    title: "URBAN Care Young Talent Platform (UYTP) Nedir?",
    desc: 'URBAN Care Young Talent Platform, her yıl farklı sanat ve spor alanlarında, gençlerin hayallerine destek olmak amacıyla burs imkanı sağlamayı hedeflediğimiz sosyal sorumluluk projesidir. UYTP kapsamında bu yılın teması olan "Dansta Fırsat Eşitliği" ile gençler arasından potansiyel dansçıları ortaya çıkarma, onlara fırsat tanıma ve uluslararası arenaya açılma yolculuklarına eşlik etmeyi hedefliyoruz.',
  },
  {
    id: "02",
    title: "Dansta Fırsat Eşitliği Kapsamında Neler Yapıyorsunuz?",
    desc: "URBAN Care Young Talent Platform, TOG ( Toplum Gönüllüleri Vakfı ) ile  “DANSTA FIRSAT EŞİTLİĞİ’’ çatısı altında yeteneğini mesleğe dönüştürme yolunda cesaret ve özgüven ile ilerleyen ancak maddi imkansızların yolunu kapattığı bir çok genci uluslararası arenanın önde gelen okullarında bale, sokak dansı ve çağdaş- modern dans olmak üzere 3 farklı dans kategorisinde eğitim imkanı sağlıyor.",
  },
  {
    id: "03",
    title: "URBAN Care Neden Böyle Bir Proje Gerçekleştirmeye Gerek Duydu?",
    desc: "Ülkemizde gençlerin hayallerini gerçekleştirme yolunda karşılaştığı engeller ve desteklerin yetersiz olması şüphesiz hayallerinden uzaklaşmalarına sebep olan unsurların başında geliyor. URBAN Care olarak hayata geçirdiğimiz “Young Talent Platform’ ile genç yeteneklere hayallerine giden yolda destek olmayı hedefliyoruz! İstiyoruz ki bu proje yıllar içerisinde çok daha fazla genç yeteneğin gelecek planlarını şekillendirerek büyüsün. Bu sebeple bu proje ile her yıl farklı sanat, spor ya da benzeri alanlardaki gençlerin hayallerine destek olmayı hedefliyoruz.",
  },
  {
    id: "04",
    title: "Kimler Başvurabilir?",
    desc: "Programa 18-28 yaş aralığında gençler katılım gösterebilecek. (Bale için 16-20 yaş)",
  },
  {
    id: "05",
    title:
      "Başvurular Nasıl Değerlendirilecek? Hangi Kriterlere Göre Seçim Yapılacak?",
    desc: "Başvuru formu doldurularak önceden çekilmiş performans videosu UTYP online sitesine yüklenecek. Jüri üyelerinin değerlendirmesi sonucunda fiziki görüşmeler gerçekleştirilecek. Fiziki görüşmelerin değerlendirilmesi sonucunda 45 adet finalist yurt dışı dans eğitimi hakkı kazanacak.",
  },
  {
    id: "06",
    title: "Kazananlara Sadece Eğitim Bursu Mu Sunulacak?",
    desc: "Yurtdışına eğitimine gitmeye hak kazananlara uçak - konaklama - sosyal hayat dahil paket program burs fonu TOG aracılığıyla sağlanacaktır.",
  },
  {
    id: "07",
    title: "Kazananların Hangi Okullara Gideceği Belli Midir?",
    desc: "Belirlenen dans kategorilerinde eğitim veren Avrupa’nın seçili okullarıyla iş birliklerimiz bulunmaktadır. Hak kazanan katılımcılara hak kazanım bilgilendirmesiyle beraber iş birliği yaptığımız okullar da seçim için iletilecektir.",
  },
  {
    id: "08",
    title:
      "Eğitim Hakkı Sadece Sizin Belirlediğiniz Okullar İçin Mi Geçerli? Hali Hazırda Kabul Alınan Bir Yaz Okulu Mevcut İse Yine Burstan Yararlanabilir Miyiz?",
    desc: "Hali hazırda kabul aldığınız bir yaz okulu varsa da burs fonuna başvurabilirsiniz. Ön elemeyi geçmeniz durumunda fiziki elemede sizlerden okul ve kabul bilgileri talep edilecektir.",
  },
  {
    id: "09",
    title:
      "Bale Kategorisinde Yaş Sınırı 16’dan Başlıyor. Bursun Kazanılması Durumunda Aile De Programa Dahil Olabilir Mi?",
    desc: "Verilen burslar bireyseldir. Bale kategorisinde belirlediğimiz okullar 18 yaş altı öğrencilere paket program sunmaktadır. Aile rızası kontrolleri sağlandığında haktan yararlanmanız mümkün olacaktır.",
  },
  {
    id: "10",
    title: "Yaz Okulu Eğitimi Sonunda Katılımcılara Sertifika Serilecek Midir?",
    desc: "Her okulun farklı yaz okulu eğitim süreci bulunmaktadır. Kazanan katılımcılarımıza kendi kategorilerinde okullar bildirilecektir.",
  },
  {
    id: "11",
    title: "Yaz Dönemi Dışında Uzun Dönemli Farklı Burs Olanaklarınız Var Mı?",
    desc: "UYTP olarak gençlerle bağımızı güçlendirmeye her zaman devam edeceğiz. Yılın ilerleyen dönemlerinde farklı burs olanaklarımız olacaktır. Duyurular kısmından takip edebilirsiniz. ",
  },
];

const SectionFaq: FC<SectionFaqProps> = ({ className = "" }) => {
  return (
    <div className={`section-faq flex flex-col lg:flex-row gap-9 ${className}`}>
      <div className="relative w-fit h-auto flex-shrink-0 flex justify-center self-start">
        <img src={faq} className="w-2/3 sm:w-fit" alt="Sıkça Sorulan Sorular" />
      </div>

      <div className={`relative flex flex-col w-full`}>
        <Heading
          border
          borderFull
          desc=""
          className="mb-9 items-center text-center float"
          borderClassName="!float-none"
        >
          <span>SIKÇA</span> SORULAN SORULAR
        </Heading>

        <div className="w-full flex flex-col gap-4">
          {data.map((item, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div
                  className={`border bg-white rounded-xl p-4 gap-6 flex justify-between transition-all text-base ${
                    open && "border-black"
                  }`}
                >
                  <div className="relative">
                    <Disclosure.Button className="flex w-full justify-between text-left font-semibold text-black leading-tight">
                      <span>{item.title}</span>
                    </Disclosure.Button>
                    <Transition
                      show={open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel
                        static
                        className="pt-4 text-base font-medium text-cGrey2"
                      >
                        {item.desc}
                      </Disclosure.Panel>
                    </Transition>
                  </div>
                  <Disclosure.Button className="self-center text-main">
                    {!open ? (
                      <PlusIcon className="w-5 h-5 flex-shrink-0" />
                    ) : (
                      <MinusIcon className="w-5 h-5 flex-shrink-0" />
                    )}
                  </Disclosure.Button>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionFaq;
