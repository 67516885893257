import React from "react";

const LogoSvg = () => {
  return (
    <svg
      className="w-full block"
      viewBox="0 0 142 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.5285 59.4095L50.028 62.1989V63.8775H49.0926V62.1989L47.5421 59.4095H48.6472L49.577 61.3554L50.4678 59.4095H51.5285Z"
        fill="#231F20"
      />
      <path
        d="M54.2956 64C53.6553 64 53.1654 63.8274 52.8257 63.4794C52.372 63.0535 52.1437 62.4383 52.1437 61.6365C52.1437 60.8348 52.372 60.2029 52.8257 59.7937C53.1654 59.4457 53.6553 59.2731 54.2956 59.2731C54.9359 59.2731 55.4258 59.4457 55.7655 59.7937C56.2164 60.2029 56.4447 60.8181 56.4447 61.6365C56.4447 62.455 56.2192 63.0535 55.7655 63.4794C55.4258 63.8274 54.9359 64 54.2956 64ZM55.1809 62.8002C55.398 62.5274 55.5066 62.1404 55.5066 61.6365C55.5066 61.1327 55.398 60.7485 55.1809 60.4729C54.9637 60.2001 54.6686 60.0637 54.2984 60.0637C53.9281 60.0637 53.6303 60.2001 53.4103 60.4729C53.1904 60.7457 53.0791 61.1327 53.0791 61.6365C53.0791 62.1404 53.1904 62.5274 53.4103 62.8002C53.6303 63.073 53.9281 63.2094 54.2984 63.2094C54.6686 63.2094 54.9637 63.073 55.1836 62.8002H55.1809Z"
        fill="#231F20"
      />
      <path
        d="M60.0581 62.1543V59.4067H61.0102V62.1543C61.0102 62.6304 60.935 62.9978 60.7875 63.2651C60.5119 63.7495 59.9857 63.9917 59.209 63.9917C58.4324 63.9917 57.9062 63.7495 57.6278 63.2651C57.4803 63.0006 57.4051 62.6304 57.4051 62.1543V59.4067H58.3572V62.1543C58.3572 62.4606 58.3934 62.686 58.4658 62.828C58.5799 63.0786 58.8277 63.2038 59.209 63.2038C59.5904 63.2038 59.8354 63.0786 59.9467 62.828C60.0191 62.686 60.0553 62.4633 60.0553 62.1543H60.0581Z"
        fill="#231F20"
      />
      <path
        d="M65.7788 63.8775H64.8407L63.0089 60.704V63.8775H62.1348V59.4095H63.1175L64.9019 62.5274V59.4095H65.7761V63.8775H65.7788Z"
        fill="#231F20"
      />
      <path
        d="M69.6512 63.7689C69.4034 63.9193 69.1 63.9972 68.7381 63.9972C68.1451 63.9972 67.658 63.7912 67.2794 63.382C66.8841 62.97 66.6864 62.4049 66.6864 61.6894C66.6864 60.974 66.8868 60.3866 67.2849 59.9495C67.683 59.5125 68.2119 59.2953 68.8689 59.2953C69.4396 59.2953 69.8961 59.4401 70.2413 59.7268C70.5865 60.0164 70.7842 60.3755 70.8371 60.8042H69.9156C69.846 60.498 69.6707 60.2864 69.3951 60.1639C69.242 60.0971 69.0694 60.0637 68.8801 60.0637C68.5182 60.0637 68.2231 60.2001 67.9892 60.4701C67.7582 60.7429 67.6412 61.1494 67.6412 61.695C67.6412 62.2406 67.7665 62.6331 68.0171 62.8614C68.2676 63.0897 68.5543 63.2038 68.8745 63.2038C69.1946 63.2038 69.4452 63.112 69.6484 62.9338C69.8516 62.7556 69.9741 62.5162 70.0186 62.2239H68.9803V61.4779H70.8482V63.8747H70.2274L70.1328 63.318C69.9518 63.5295 69.7904 63.6799 69.6456 63.7662L69.6512 63.7689Z"
        fill="#231F20"
      />
      <path
        d="M77.2343 59.4095V60.2001H75.8925V63.8775H74.9487V60.2001H73.6014V59.4095H77.2315H77.2343Z"
        fill="#231F20"
      />
      <path
        d="M80.1963 62.9589H78.5455L78.2365 63.8775H77.2566L78.8573 59.4095H79.9151L81.5019 63.8775H80.4858L80.1963 62.9589ZM79.9346 62.1877L79.3751 60.4284L78.796 62.1877H79.9346Z"
        fill="#231F20"
      />
      <path
        d="M82.3175 59.4095H83.2557V63.073H85.4771V63.8775H82.3203V59.4095H82.3175Z"
        fill="#231F20"
      />
      <path
        d="M89.4942 61.9261H87.3173V63.0758H89.8004V63.8803H86.4042V59.4123H89.6919V60.2029H87.32V61.1522H89.497V61.9288L89.4942 61.9261Z"
        fill="#231F20"
      />
      <path
        d="M94.402 63.8775H93.4639L91.6321 60.704V63.8775H90.758V59.4095H91.7407L93.5251 62.5274V59.4095H94.3993V63.8775H94.402Z"
        fill="#231F20"
      />
      <path
        d="M98.7811 59.4095V60.2001H97.4392V63.8775H96.4955V60.2001H95.1482V59.4095H98.7783H98.7811Z"
        fill="#231F20"
      />
      <path
        d="M103.461 62.2713H102.509V63.8775H101.579V59.4095H103.53C103.981 59.4095 104.34 59.5236 104.608 59.7547C104.875 59.9857 105.008 60.3421 105.008 60.8237C105.008 61.3498 104.875 61.7228 104.608 61.9428C104.34 62.1599 103.959 62.2713 103.461 62.2713ZM103.898 61.3415C104.02 61.2357 104.081 61.0631 104.081 60.832C104.081 60.601 104.02 60.4339 103.898 60.3337C103.775 60.2335 103.603 60.1862 103.383 60.1862H102.509V61.5029H103.383C103.603 61.5029 103.775 61.45 103.9 61.3415H103.898Z"
        fill="#231F20"
      />
      <path
        d="M106.459 59.4095H107.397V63.073H109.618V63.8775H106.462V59.4095H106.459Z"
        fill="#231F20"
      />
      <path
        d="M113.635 62.9589H111.985L111.676 63.8775H110.696L112.296 59.4095H113.354L114.941 63.8775H113.925L113.635 62.9589ZM113.374 62.1877L112.814 60.4284L112.235 62.1877H113.374Z"
        fill="#231F20"
      />
      <path
        d="M119.056 59.4095V60.2001H117.714V63.8775H116.77V60.2001H115.423V59.4095H119.053H119.056Z"
        fill="#231F20"
      />
      <path
        d="M123.529 60.2001H121.283V61.2273H123.251V62.004H121.283V63.8775H120.353V59.4151H123.532V60.2001H123.529Z"
        fill="#231F20"
      />
      <path
        d="M126.886 64C126.246 64 125.756 63.8274 125.417 63.4794C124.963 63.0535 124.735 62.4383 124.735 61.6365C124.735 60.8348 124.963 60.2029 125.417 59.7937C125.756 59.4457 126.246 59.2731 126.886 59.2731C127.527 59.2731 128.017 59.4457 128.356 59.7937C128.807 60.2029 129.036 60.8181 129.036 61.6365C129.036 62.455 128.81 63.0535 128.356 63.4794C128.017 63.8274 127.527 64 126.886 64ZM127.772 62.8002C127.989 62.5274 128.097 62.1404 128.097 61.6365C128.097 61.1327 127.989 60.7485 127.772 60.4729C127.555 60.2001 127.26 60.0637 126.889 60.0637C126.519 60.0637 126.221 60.2001 126.001 60.4729C125.781 60.7457 125.67 61.1327 125.67 61.6365C125.67 62.1404 125.781 62.5274 126.001 62.8002C126.221 63.073 126.519 63.2094 126.889 63.2094C127.26 63.2094 127.555 63.073 127.775 62.8002H127.772Z"
        fill="#231F20"
      />
      <path
        d="M132.398 62.1237H131.432V63.8747H130.517V59.4067H132.716C133.03 59.4123 133.273 59.4512 133.442 59.5208C133.612 59.5904 133.754 59.6962 133.874 59.8326C133.971 59.9468 134.049 60.072 134.105 60.2084C134.161 60.3449 134.191 60.5035 134.191 60.6789C134.191 60.8905 134.138 61.0993 134.03 61.3053C133.921 61.5113 133.746 61.656 133.498 61.7395C133.704 61.8231 133.852 61.94 133.938 62.0931C134.024 62.2462 134.066 62.4773 134.066 62.7918V63.0925C134.066 63.2957 134.074 63.4349 134.091 63.5073C134.116 63.6214 134.172 63.7077 134.261 63.7606V63.872H133.231C133.203 63.7717 133.183 63.6938 133.17 63.6326C133.144 63.5073 133.133 63.3792 133.131 63.2484L133.125 62.8336C133.122 62.5496 133.069 62.3576 132.969 62.2629C132.869 62.1683 132.68 62.1209 132.404 62.1209L132.398 62.1237ZM132.966 61.3108C133.153 61.2273 133.245 61.0575 133.245 60.807C133.245 60.5369 133.156 60.3532 132.975 60.2613C132.874 60.2084 132.721 60.1834 132.518 60.1834H131.43V61.3832H132.49C132.702 61.3832 132.861 61.3582 132.964 61.3108H132.966Z"
        fill="#231F20"
      />
      <path
        d="M136.599 63.8775H135.725V59.4095H137.086L137.902 62.9227L138.712 59.4095H140.06V63.8775H139.185V60.8543C139.185 60.768 139.185 60.6455 139.188 60.4896C139.191 60.3337 139.191 60.2112 139.191 60.1277L138.342 63.8775H137.432L136.588 60.1277C136.588 60.2112 136.588 60.3337 136.591 60.4896C136.594 60.6455 136.594 60.768 136.594 60.8543V63.8775H136.599Z"
        fill="#231F20"
      />
      <mask
        id="mask0_1_491"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="142"
        height="47"
      >
        <path
          d="M0.295074 27.1311V1.03837C0.295074 0.453763 0.684809 0 1.26941 0H10.5507C11.1353 0 11.589 0.453763 11.589 1.03837V27.0671C11.589 32.1949 13.8606 35.1791 18.3398 35.1791C22.819 35.1791 25.0906 32.1921 25.0906 27.0671V1.03837C25.0906 0.453763 25.5443 0 26.1289 0H35.4102C35.9948 0 36.4485 0.453763 36.4485 1.03837V27.1311C36.4485 39.8532 28.8543 46.2782 18.3398 46.2782C7.8253 46.2782 0.295074 39.8532 0.295074 27.1311ZM51.3754 44.3964V24.6646L38.1355 1.1692C37.7458 0.389734 38.0047 0 38.7842 0H48.3911C48.9117 0 49.3654 0.194868 49.6243 0.64863L57.0237 14.4731L64.4231 0.64863C64.682 0.194868 65.0718 0 65.5923 0H75.1993C75.9788 0 76.3017 0.389734 75.912 1.1692L62.6721 24.7955V44.3964C62.6721 44.981 62.2183 45.4347 61.6337 45.4347H52.3525C51.7679 45.4347 51.3782 44.981 51.3782 44.3964H51.3754ZM86.0979 44.3964V9.99669H77.5962C77.0116 9.99669 76.6218 9.54293 76.6218 8.95833V1.04115C76.6218 0.456546 77.0116 0.00278441 77.5962 0.00278441H105.83C106.414 0.00278441 106.868 0.456546 106.868 1.04115V8.95833C106.868 9.54293 106.414 9.99669 105.83 9.99669H97.3919V44.3964C97.3919 44.981 96.9381 45.4347 96.3535 45.4347H87.0723C86.4877 45.4347 86.0979 44.981 86.0979 44.3964ZM109.721 44.3964V1.03837C109.721 0.453763 110.111 0 110.696 0H124.974C136.137 0 141.396 6.10213 141.396 15.0577C141.396 24.0132 136.14 30.1154 124.974 30.1154H121.015V44.3936C121.015 44.9782 120.562 45.4319 119.977 45.4319H110.696C110.111 45.4319 109.721 44.9782 109.721 44.3936V44.3964ZM124.715 20.1214C127.699 20.1214 130.622 19.342 130.622 15.0577C130.622 10.7734 127.702 9.99391 124.715 9.99391H121.015V20.1187H124.715V20.1214Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_491)">
        <path
          d="M42.1025 41.1504L61.5892 37.5314L78.8489 35.026V51.7289L69.1056 63.9777L52.4026 55.9046L42.1025 41.1504Z"
          fill="url(#paint0_linear_1_491)"
        />
        <path
          d="M8.97504 33.0773L16.4913 11.9203L32.3591 -10.6286L49.062 23.334L34.3078 30.0151L8.97504 33.0773Z"
          fill="url(#paint1_linear_1_491)"
        />
        <path
          d="M7.30478 29.4584L28.4618 42.8207L44.8863 42.5423L49.8972 28.9016L44.608 20.8285L7.30478 29.4584Z"
          fill="url(#paint2_linear_1_491)"
        />
        <path
          d="M-2.99539 51.1721L0.901955 40.3153L12.0372 28.3448L26.7915 45.8829L30.1321 57.2965L-2.99539 51.1721Z"
          fill="url(#paint3_linear_1_491)"
        />
        <path
          d="M12.0372 28.3448L44.8863 42.5423L38.2051 54.7911L30.1321 57.2965L12.0372 28.3448Z"
          fill="url(#paint4_linear_1_491)"
        />
        <path
          d="M74.1164 -14.8044L76.3434 0.228264L86.3652 6.63105L92.2112 6.07428L95.2734 -1.72041L84.6949 -11.1854L74.1164 -14.8044Z"
          fill="url(#paint5_linear_1_491)"
        />
        <path
          d="M169.045 -16.4746L172.385 12.7555L151.785 4.40399L130.906 -23.1558L150.115 -21.4855L169.045 -16.4746Z"
          fill="url(#paint6_linear_1_491)"
        />
        <path
          d="M32.3591 -10.6286L44.6079 20.8285L54.0729 21.6637L66.6001 15.5393L54.9081 0.228269L32.3591 -10.6286Z"
          fill="url(#paint7_linear_1_491)"
        />
        <path
          d="M32.3591 -10.6286L74.1164 -14.8044L72.1677 1.06341L66.6001 15.5393L32.3591 -10.6286Z"
          fill="url(#paint8_linear_1_491)"
        />
        <path
          d="M44.6079 20.8285L44.8863 42.5423L60.1973 33.9125L66.6001 15.5393L44.6079 20.8285Z"
          fill="url(#paint9_linear_1_491)"
        />
        <path
          d="M44.8863 42.5423L78.2922 38.0882L74.9516 20.5501L66.6001 15.5393L44.8863 42.5423Z"
          fill="url(#paint10_linear_1_491)"
        />
        <path
          d="M74.1164 -14.8044L86.3652 6.63105L76.3435 13.5906L66.6001 15.5393L74.1164 -14.8044Z"
          fill="url(#paint11_linear_1_491)"
        />
        <path
          d="M78.2921 38.0882L85.5301 24.1691L86.3652 6.63105L66.6001 15.5393L78.2921 38.0882Z"
          fill="url(#paint12_linear_1_491)"
        />
        <path
          d="M95.2734 -1.72041L130.906 -23.1558L141.485 4.68238L142.32 31.9638L117.544 18.6015L95.2734 -1.72041Z"
          fill="url(#paint13_linear_1_491)"
        />
        <path
          d="M130.906 -23.1558L172.385 12.7555L160.136 23.8907L142.042 31.6854L130.906 -23.1558Z"
          fill="url(#paint14_linear_1_491)"
        />
        <path
          d="M78.2921 38.0882L82.1895 23.0556L86.3652 6.63105L98.0573 18.6015L114.482 43.0991L93.0464 43.6558L78.2921 38.0882Z"
          fill="url(#paint15_linear_1_491)"
        />
        <path
          d="M86.3652 6.63105L95.2735 -1.72041L108.636 14.7041L113.925 42.8207L86.3652 6.63105Z"
          fill="url(#paint16_linear_1_491)"
        />
        <path
          d="M95.2734 -1.72041L142.042 31.6854L123.947 40.3153L113.925 42.8207L95.2734 -1.72041Z"
          fill="url(#paint17_linear_1_491)"
        />
        <path
          d="M116.709 51.1722L127.287 43.6558L142.042 31.6854L113.925 42.8207L114.203 46.4397L116.709 51.1722Z"
          fill="url(#paint18_linear_1_491)"
        />
        <path
          d="M30.132 57.2966L44.8863 42.5423L44.6079 59.802L40.9889 75.113L30.132 57.2966Z"
          fill="url(#paint19_linear_1_491)"
        />
        <path
          d="M44.8863 42.5423L70.4975 60.9155L56.0216 69.8237L40.989 75.113L44.8863 42.5423Z"
          fill="url(#paint20_linear_1_491)"
        />
        <path
          d="M116.709 49.7802L142.042 31.6854L144.547 39.2017L146.496 48.3883L132.577 49.5019L116.709 49.7802Z"
          fill="url(#paint21_linear_1_491)"
        />
        <path
          d="M12.0372 28.3448L32.3591 -10.6286L7.02637 -8.40157L5.91284 6.07429L12.0372 28.3448Z"
          fill="url(#paint22_linear_1_491)"
        />
        <path
          d="M70.4974 60.9155L78.2921 38.0882L88.3139 42.5423L96.1086 49.7802L81.3543 58.9668L70.4974 60.9155Z"
          fill="url(#paint23_linear_1_491)"
        />
        <path
          d="M78.2921 38.0882L113.925 42.8207L106.13 48.3883L96.1086 49.7802L78.2921 38.0882Z"
          fill="url(#paint24_linear_1_491)"
        />
        <path
          d="M113.925 42.8207L116.709 49.7802H96.1086L113.925 42.8207Z"
          fill="url(#paint25_linear_1_491)"
        />
        <path
          d="M-8.00623 1.06342L2.01552 9.97164L12.0373 28.3448L-5.22241 26.1178L-12.7387 20.2718L-8.00623 1.06342Z"
          fill="url(#paint26_linear_1_491)"
        />
        <path
          d="M-2.99537 51.1722L12.0373 28.3448L-8.00625 30.5719L-20.5334 36.4179L-13.5739 50.0586L-2.99537 51.1722Z"
          fill="url(#paint27_linear_1_491)"
        />
        <path
          d="M-12.7387 20.2718L12.0373 28.3448L-20.5334 36.4179L-12.7387 20.2718Z"
          fill="url(#paint28_linear_1_491)"
        />
        <path
          d="M-8.00626 1.06341L12.0372 28.3448L7.02637 -8.40157L-2.99538 -5.33937L-8.00626 1.06341Z"
          fill="url(#paint29_linear_1_491)"
        />
        <path
          d="M-8.00626 1.06341L-7.72787 -24.2693L4.24255 -15.6395L7.02637 -8.40157L-8.00626 1.06341Z"
          fill="url(#paint30_linear_1_491)"
        />
        <mask
          id="mask1_1_491"
          maskUnits="userSpaceOnUse"
          x="76"
          y="-5"
          width="73"
          height="54"
        >
          <path
            d="M148.597 -4.27595H76.2655V48.5387H148.597V-4.27595Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1_491)">
          <path
            d="M108.065 -14.0722L138.061 -6.70623L160.423 2.7671L138.322 10.1331L115.957 9.08081L108.065 -14.0722Z"
            fill="url(#paint31_linear_1_491)"
          />
          <path
            d="M110.696 -11.706L117.009 6.97624L99.3823 3.29325L79.1217 -5.91563L95.4349 -8.54634L110.696 -11.706Z"
            fill="url(#paint32_linear_1_491)"
          />
          <path
            d="M94.9087 48.2853L102.013 28.2892L117.009 6.97624L132.796 39.0765L118.852 45.3902L94.9087 48.2853Z"
            fill="url(#paint33_linear_1_491)"
          />
          <path
            d="M93.3303 44.864L113.326 57.4942L128.852 57.2297L133.587 44.3379L128.587 36.7074L93.3303 44.864Z"
            fill="url(#paint34_linear_1_491)"
          />
          <path
            d="M83.5953 65.3863L87.2783 55.1252L97.8039 43.8117L111.748 60.3866L114.905 71.1739L83.5953 65.3863Z"
            fill="url(#paint35_linear_1_491)"
          />
          <path
            d="M97.8039 43.8117L128.852 57.2297L122.535 68.8077L114.905 71.1739L97.8039 43.8117Z"
            fill="url(#paint36_linear_1_491)"
          />
          <path
            d="M110.696 -11.706L156.478 3.02878L159.635 -5.65395L161.214 -14.8628H143.058L110.696 -11.706Z"
            fill="url(#paint37_linear_1_491)"
          />
          <path
            d="M117.009 6.97624L128.587 36.7074L137.535 37.498L149.374 31.7077L138.322 17.2374L117.009 6.97624Z"
            fill="url(#paint38_linear_1_491)"
          />
          <path
            d="M117.009 6.97624L156.478 3.02879L154.636 18.028L149.374 31.7077L117.009 6.97624Z"
            fill="url(#paint39_linear_1_491)"
          />
          <path
            d="M128.587 36.7074L128.852 57.2298L143.322 49.0732L149.374 31.7077L128.587 36.7074Z"
            fill="url(#paint40_linear_1_491)"
          />
          <path
            d="M128.852 57.2298L160.423 53.0206L157.266 36.443L149.374 31.7077L128.852 57.2298Z"
            fill="url(#paint41_linear_1_491)"
          />
          <path
            d="M91.2257 -4.60167L117.009 6.97624L103.591 9.60695L93.0686 9.08081L89.909 0.662535L91.2257 -4.60167Z"
            fill="url(#paint42_linear_1_491)"
          />
          <path
            d="M97.8039 43.8117L117.009 6.97624L93.0686 9.08081L92.0163 22.7633L97.8039 43.8117Z"
            fill="url(#paint43_linear_1_491)"
          />
          <path
            d="M80.174 23.2894L82.543 5.39782L79.1217 -5.91562L76.491 -9.59862L65.442 -14.8628H64.6514V-12.4938L71.2296 10.1331L80.174 23.2894Z"
            fill="url(#paint44_linear_1_491)"
          />
          <path
            d="M64.6514 -13.8105L78.86 18.028L80.174 29.3415L73.0724 39.0765L69.125 17.7635L64.6514 -9.86308V-13.8105Z"
            fill="url(#paint45_linear_1_491)"
          />
          <path
            d="M78.86 18.028L88.3306 26.4463L97.8039 43.8117L81.4907 41.7072L74.3864 36.1813L78.86 18.028Z"
            fill="url(#paint46_linear_1_491)"
          />
          <path
            d="M83.5953 65.3863L97.8039 43.8117L78.86 45.9163L67.0204 51.4422L73.5986 64.3341L83.5953 65.3863Z"
            fill="url(#paint47_linear_1_491)"
          />
          <path
            d="M74.3864 36.1813L97.8039 43.8117L67.0204 51.4422L74.3864 36.1813Z"
            fill="url(#paint48_linear_1_491)"
          />
          <path
            d="M78.86 18.028L97.8039 43.8117L93.0686 9.0808L83.5953 11.976L78.86 18.028Z"
            fill="url(#paint49_linear_1_491)"
          />
          <path
            d="M78.86 18.028L79.1217 -5.91563L90.4379 2.24096L93.0686 9.08081L78.86 18.028Z"
            fill="url(#paint50_linear_1_491)"
          />
          <path
            d="M79.1217 -5.91563L91.2257 -4.60166L93.0686 9.08081L79.1217 -5.91563Z"
            fill="url(#paint51_linear_1_491)"
          />
        </g>
      </g>
      <path d="M73.9076 53.544H51.8904V56.776H73.9076V53.544Z" fill="#FFC41B" />
      <path d="M95.9248 53.544H73.9076V56.776H95.9248V53.544Z" fill="#DF1683" />
      <path d="M117.942 53.544H95.9248V56.776H117.942V53.544Z" fill="#3776BA" />
      <path d="M139.956 53.544H117.939V56.776H139.956V53.544Z" fill="#CE1F3A" />
      <path
        d="M6.83427 60.4144C6.83427 61.2746 6.59487 61.9233 6.12718 62.3436C5.65115 62.7695 4.91622 62.9839 3.9391 62.9839C2.96198 62.9839 2.22149 62.764 1.74545 62.3325C1.2722 61.9066 1.0328 61.2468 1.0328 60.3727V54.7215H0V60.3727C0 61.6143 0.347978 62.5413 1.03558 63.1287C1.67029 63.6826 2.65019 63.9638 3.94189 63.9638C5.21688 63.9638 6.19121 63.6882 6.83427 63.1426C7.52188 62.5552 7.86986 61.6338 7.86986 60.3977V54.7215H6.83706V60.4117L6.83427 60.4144Z"
        fill="#001028"
      />
      <path
        d="M16.9312 57.2158C16.9312 55.5623 15.8316 54.7243 13.663 54.7243H9.24228V63.8747H10.2751V59.9523H13.5433C14.3033 59.9523 14.8739 60.086 15.2414 60.3476C15.595 60.601 15.7648 60.974 15.7648 61.489V63.8747H16.8003V61.4612C16.8003 60.5119 16.4273 59.8215 15.6924 59.4039C16.5136 58.9557 16.9284 58.2208 16.9284 57.2131L16.9312 57.2158ZM15.8984 57.2548C15.8984 57.8283 15.6534 58.2681 15.1718 58.5576C14.757 58.8138 14.1863 58.9446 13.482 58.9446H10.2779V55.707H13.6908C14.4452 55.707 15.0131 55.8406 15.3778 56.1051C15.7286 56.3584 15.8984 56.737 15.8984 57.2548Z"
        fill="#001028"
      />
      <path
        d="M24.6424 59.0532C25.3968 58.6189 25.7754 58.0009 25.7754 57.2158C25.7754 56.3751 25.4413 55.7265 24.7871 55.2867C24.2304 54.9136 23.5149 54.7243 22.6631 54.7243H18.1004V63.8747H22.4961C23.4481 63.8747 24.2443 63.691 24.8679 63.3319C25.6613 62.8753 26.0621 62.171 26.0621 61.2412C26.0621 60.189 25.5861 59.454 24.6424 59.0504V59.0532ZM24.7426 57.1518C24.7426 57.6473 24.5282 58.0287 24.1079 58.2848C23.7237 58.5159 23.2254 58.6328 22.6241 58.6328H19.1332V55.7042H22.638C23.2226 55.7042 23.7098 55.8128 24.0856 56.0299C24.5338 56.2861 24.7426 56.6396 24.7426 57.149V57.1518ZM19.1332 62.8948V59.6183H22.5963C23.2867 59.6183 23.8518 59.7324 24.2693 59.9579C24.7871 60.2363 25.0377 60.6622 25.0377 61.258C25.0377 61.8119 24.8011 62.2211 24.3111 62.5079C23.888 62.764 23.3256 62.8948 22.6464 62.8948H19.1304H19.1332Z"
        fill="#001028"
      />
      <path
        d="M31.4182 54.7243H30.5691L26.2793 63.8747H27.4429L28.7262 61.116H33.172L34.4275 63.8747H35.5633L31.4516 54.7911L31.421 54.7215L31.4182 54.7243ZM32.7294 60.1249H29.1856L30.9951 56.3139L32.7266 60.1249H32.7294Z"
        fill="#001028"
      />
      <path
        d="M43.1019 58.1011V62.1376C42.456 61.3387 41.4984 60.1806 40.2457 58.6913C38.8816 57.0544 37.8098 55.746 37.0638 54.7995L37.0081 54.7271H36.0449V63.8775H37.0777V56.4224L43.1743 63.8775H44.1375V58.1011H43.1047H43.1019Z"
        fill="#001028"
      />
      <path
        d="M40.499 53.26C41.0446 53.26 41.4455 53.4382 41.6849 53.7862L41.7545 53.8864L42.1999 53.608L42.122 53.4967C41.774 53.0039 41.2284 52.7534 40.499 52.7534C39.8754 52.7534 39.3716 52.9343 38.9985 53.2907C38.6255 53.6498 38.4362 54.1342 38.4362 54.7355C38.4362 55.3228 38.6199 55.7961 38.9818 56.1441C39.3437 56.4893 39.8364 56.6647 40.4461 56.6647C41.1476 56.6647 41.696 56.4141 42.0746 55.9214L42.1609 55.81L41.71 55.5233L41.6404 55.6179C41.3675 55.977 40.9639 56.1608 40.4433 56.1608C39.9812 56.1608 39.6165 56.0299 39.3548 55.771C39.0932 55.5121 38.9623 55.1558 38.9623 54.7132C38.9623 54.2706 39.1015 53.9087 39.3743 53.647C39.6499 53.3853 40.0285 53.2545 40.5018 53.2545L40.499 53.26Z"
        fill="#DF1683"
      />
      <path
        d="M44.7053 54.0339C44.4659 53.8363 44.1569 53.7333 43.7811 53.7333C43.3274 53.7333 42.9655 53.878 42.701 54.1648C42.456 54.4348 42.3307 54.7884 42.3307 55.2199C42.3307 55.6513 42.4532 55.9854 42.6954 56.2582C42.9543 56.5449 43.3134 56.6925 43.7588 56.6925C44.143 56.6925 44.4604 56.5867 44.7026 56.3807V56.6396H45.2259V53.7472H44.7026V54.0367L44.7053 54.0339ZM42.8513 55.2226C42.8513 54.9359 42.9293 54.7021 43.0796 54.5239C43.2466 54.3318 43.4665 54.2399 43.7533 54.2399C44.0623 54.2399 44.3045 54.329 44.4687 54.5044C44.6246 54.677 44.7053 54.9192 44.7053 55.2226C44.7053 55.501 44.619 55.7349 44.4464 55.9186C44.2739 56.1023 44.0484 56.1914 43.7561 56.1914C43.4638 56.1914 43.2383 56.0995 43.0796 55.9186C42.9265 55.7432 42.8513 55.5177 42.8513 55.2254V55.2226Z"
        fill="#DF1683"
      />
      <path
        d="M46.203 54.0367V53.7917H45.6769V56.634H46.203V55.4704C46.203 55.1168 46.2754 54.8245 46.4146 54.6046C46.5733 54.3596 46.8322 54.2399 47.2024 54.2399H47.3305V53.7361H47.2024C46.7932 53.7361 46.4563 53.8391 46.203 54.0395V54.0367Z"
        fill="#DF1683"
      />
      <path
        d="M50.3064 55.1224C50.3064 54.7021 50.17 54.3624 49.9027 54.1147C49.6355 53.8641 49.2792 53.7361 48.8393 53.7361C48.3995 53.7361 48.0404 53.8753 47.7703 54.1509C47.5031 54.4237 47.3695 54.7856 47.3695 55.2226C47.3695 55.6597 47.4892 56.0188 47.7258 56.2777C47.9791 56.5533 48.3438 56.6953 48.8143 56.6953C49.1344 56.6953 49.4156 56.6285 49.6494 56.5004C49.925 56.3501 50.1199 56.0912 50.234 55.7293L50.2535 55.6653H49.7051C49.5603 56.0188 49.2736 56.1914 48.8254 56.1914C48.2659 56.1914 47.9708 55.952 47.9012 55.4426L50.3064 55.437V55.1252V55.1224ZM47.9179 54.9359C47.9624 54.7382 48.0487 54.5824 48.1851 54.4626C48.3577 54.3123 48.5721 54.2399 48.8421 54.2399C49.1121 54.2399 49.3265 54.3151 49.488 54.4626C49.6216 54.5824 49.7079 54.741 49.7413 54.9359H47.9207H47.9179Z"
        fill="#DF1683"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_491"
          x1="47.0633"
          y1="33.7761"
          x2="80.5053"
          y2="54.8357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4AE58" />
          <stop offset="1" stopColor="#C6AF6F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_491"
          x1="36.6907"
          y1="17.1205"
          x2="19.3141"
          y2="10.726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E46495" />
          <stop offset="1" stopColor="#DF3591" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_491"
          x1="18.0531"
          y1="22.6324"
          x2="42.1693"
          y2="34.6279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF7A4F" />
          <stop offset="1" stopColor="#EA806C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_491"
          x1="16.2798"
          y1="55.0639"
          x2="3.30996"
          y2="35.7164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C417" />
          <stop offset="1" stopColor="#F5A41F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_491"
          x1="11.4972"
          y1="28.9267"
          x2="45.6992"
          y2="56.5282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39137" />
          <stop offset="1" stopColor="#F3B129" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_491"
          x1="72.6966"
          y1="-12.9141"
          x2="94.3018"
          y2="7.13492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#763795" />
          <stop offset="1" stopColor="#71459B" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_491"
          x1="134.392"
          y1="-26.6217"
          x2="176.636"
          y2="6.79808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F2F8B" />
          <stop offset="1" stopColor="#452F91" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1_491"
          x1="29.4445"
          y1="-6.52528"
          x2="64.7851"
          y2="23.1447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC3493" />
          <stop offset="1" stopColor="#CE4C9B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1_491"
          x1="36.9664"
          y1="-21.7472"
          x2="79.9624"
          y2="6.72848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BD3292" />
          <stop offset="1" stopColor="#9E3E97" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1_491"
          x1="51.7763"
          y1="25.8283"
          x2="59.3594"
          y2="31.9694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E46495" />
          <stop offset="1" stopColor="#DB7786" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1_491"
          x1="52.3971"
          y1="27.4568"
          x2="68.4903"
          y2="35.0789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA3895" />
          <stop offset="1" stopColor="#E14193" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1_491"
          x1="69.5899"
          y1="-4.75477"
          x2="75.486"
          y2="8.79407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#843B96" />
          <stop offset="1" stopColor="#944B9D" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1_491"
          x1="74.3725"
          y1="17.1511"
          x2="81.9918"
          y2="22.1397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#883F98" />
          <stop offset="1" stopColor="#673293" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1_491"
          x1="110.131"
          y1="-15.5226"
          x2="138.272"
          y2="13.8022"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B479D" />
          <stop offset="1" stopColor="#4C469C" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1_491"
          x1="140.633"
          y1="2.87011"
          x2="159.633"
          y2="-3.50484"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#463895" />
          <stop offset="1" stopColor="#4A4FA2" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1_491"
          x1="79.0633"
          y1="19.4533"
          x2="103.953"
          y2="41.6014"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9882AE" />
          <stop offset="1" stopColor="#55499E" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1_491"
          x1="90.4881"
          y1="0.328486"
          x2="115.339"
          y2="36.9719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#816CB0" />
          <stop offset="1" stopColor="#574B9F" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1_491"
          x1="106.815"
          y1="15.8288"
          x2="123.173"
          y2="32.3842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9680AF" />
          <stop offset="1" stopColor="#4F65AF" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1_491"
          x1="114.195"
          y1="32.5345"
          x2="142.609"
          y2="49.1845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4973B9" />
          <stop offset="1" stopColor="#4E6AB2" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1_491"
          x1="33.0411"
          y1="40.95"
          x2="49.0119"
          y2="73.0084"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9C616" />
          <stop offset="1" stopColor="#F5BE1C" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1_491"
          x1="44.0929"
          y1="56.9319"
          x2="58.1317"
          y2="70.2859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBAD21" />
          <stop offset="1" stopColor="#E6C71F" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1_491"
          x1="122.421"
          y1="46.7292"
          x2="142.604"
          y2="40.5463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4971B7" />
          <stop offset="1" stopColor="#464CA0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1_491"
          x1="16.2519"
          y1="-2.72536"
          x2="26.8583"
          y2="17.9389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC3192" />
          <stop offset="1" stopColor="#DB1588" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1_491"
          x1="67.2209"
          y1="41.8185"
          x2="94.0569"
          y2="63.6464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B8B667" />
          <stop offset="1" stopColor="#9A9C9A" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1_491"
          x1="81.3293"
          y1="39.1989"
          x2="110.991"
          y2="48.0905"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#938FA8" />
          <stop offset="1" stopColor="#6688B4" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1_491"
          x1="97.4364"
          y1="43.9955"
          x2="115.325"
          y2="49.435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F7CBE" />
          <stop offset="1" stopColor="#5486B7" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1_491"
          x1="-14.8628"
          y1="4.63227"
          x2="8.37933"
          y2="30.2935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9454F" />
          <stop offset="1" stopColor="#F06D35" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1_491"
          x1="-9.61808"
          y1="42.0329"
          x2="-1.01329"
          y2="34.9648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7AD1A" />
          <stop offset="1" stopColor="#F6991E" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1_491"
          x1="-22.148"
          y1="23.5595"
          x2="10.4727"
          y2="39.636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F37D20" />
          <stop offset="1" stopColor="#F78F1E" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1_491"
          x1="-3.01765"
          y1="12.0317"
          x2="7.61932"
          y2="10.2862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E42E62" />
          <stop offset="1" stopColor="#DE4699" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_1_491"
          x1="4.44298"
          y1="-4.30658"
          x2="-9.28125"
          y2="-12.4409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED3C7D" />
          <stop offset="1" stopColor="#D11776" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_1_491"
          x1="113.925"
          y1="-3.66352"
          x2="150.847"
          y2="10.0273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB1B8D" />
          <stop offset="1" stopColor="#A6268F" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_1_491"
          x1="90.076"
          y1="-11.9593"
          x2="110.217"
          y2="1.1664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C9158A" />
          <stop offset="1" stopColor="#D20F8B" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_1_491"
          x1="121.104"
          y1="33.2026"
          x2="104.68"
          y2="27.1589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E46495" />
          <stop offset="1" stopColor="#DF3591" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_1_491"
          x1="103.491"
          y1="38.4139"
          x2="126.282"
          y2="49.7496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF7A4F" />
          <stop offset="1" stopColor="#EA806C" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_1_491"
          x1="101.813"
          y1="69.0638"
          x2="89.5554"
          y2="50.7796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C417" />
          <stop offset="1" stopColor="#F5A41F" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_1_491"
          x1="97.2916"
          y1="44.3602"
          x2="129.617"
          y2="70.4501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39137" />
          <stop offset="1" stopColor="#F3B129" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_1_491"
          x1="116.308"
          y1="-20.4889"
          x2="160.498"
          y2="-4.27874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9F248A" />
          <stop offset="1" stopColor="#682B88" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_1_491"
          x1="114.256"
          y1="10.8541"
          x2="147.659"
          y2="38.8983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC3493" />
          <stop offset="1" stopColor="#CE4C9B" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_1_491"
          x1="121.363"
          y1="-3.53268"
          x2="162.004"
          y2="23.3813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BD3292" />
          <stop offset="1" stopColor="#9E3E97" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_1_491"
          x1="135.363"
          y1="41.4344"
          x2="142.529"
          y2="47.2358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E46495" />
          <stop offset="1" stopColor="#DB7786" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_1_491"
          x1="135.948"
          y1="42.971"
          x2="151.161"
          y2="50.1756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA3895" />
          <stop offset="1" stopColor="#E14193" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_1_491"
          x1="90.8583"
          y1="1.28054"
          x2="112.528"
          y2="11.5918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D70D87" />
          <stop offset="1" stopColor="#DE499A" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_1_491"
          x1="101.788"
          y1="14.4452"
          x2="111.812"
          y2="33.9765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DC3192" />
          <stop offset="1" stopColor="#DB1588" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_1_491"
          x1="70.4334"
          y1="-8.24568"
          x2="80.9952"
          y2="14.8071"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DF1E6F" />
          <stop offset="1" stopColor="#DA0D83" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_1_491"
          x1="65.7371"
          y1="-6.11049"
          x2="76.9587"
          y2="31.9165"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE1774" />
          <stop offset="1" stopColor="#E8434B" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_1_491"
          x1="72.3793"
          y1="21.3992"
          x2="94.3464"
          y2="45.6518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9454F" />
          <stop offset="1" stopColor="#F06D35" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_1_491"
          x1="77.3372"
          y1="56.7481"
          x2="85.4688"
          y2="50.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7AD1A" />
          <stop offset="1" stopColor="#F6991E" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_1_491"
          x1="65.4921"
          y1="39.2908"
          x2="96.3257"
          y2="54.4821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F37D20" />
          <stop offset="1" stopColor="#F78F1E" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_1_491"
          x1="83.573"
          y1="28.3922"
          x2="93.6282"
          y2="26.7441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E42E62" />
          <stop offset="1" stopColor="#DE4699" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_1_491"
          x1="90.6272"
          y1="12.9531"
          x2="77.6546"
          y2="5.2642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED3C7D" />
          <stop offset="1" stopColor="#D11776" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_1_491"
          x1="84.5836"
          y1="-6.10493"
          x2="92.6761"
          y2="5.48968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DA0E81" />
          <stop offset="1" stopColor="#E53172" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoSvg;
