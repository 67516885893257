import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface SectionApplicationProcessProps {
  className?: string;
  headCenter?: boolean;
}

const data = [
  {
    id: "01",
    color: "bg-cYellow",
    title: "25 OCAK / 7 MART",
    desc: "BAŞVURU <br class='custom-br' />ARALIĞI",
  },
  {
    id: "02",
    color: "bg-main",
    title: "18 MART / 22 MART",
    desc: "ÖN <br class='custom-br' />DEĞERLENDİRME",
  },
  {
    id: "03",
    color: "bg-cBlue",
    title: "25 MART / 29 MART",
    desc: "FİNAL <br class='custom-br' />DEĞERLENDİRME",
  },
  {
    id: "04",
    color: "bg-cRed",
    title: "1 NİSAN",
    desc: "SONUÇLARIN <br class='custom-br' />AÇIKLANMASI",
  },
];

const SectionApplicationProcess: FC<SectionApplicationProcessProps> = ({
  className = "",
  headCenter = false,
}) => {
  return (
    <div
      className={`section-application-proccess ${className}`}
      id="basvuru-sureci"
    >
      <Heading
        border
        isCenter={headCenter}
        desc=""
        className={`mb-0 container ${headCenter && "items-center"}`}
      >
        <span>BAŞVURU</span> SÜRECİ
      </Heading>
      <div className="md:container flex gap-6 pt-28 pb-4 overflow-x-auto no-scrollbar">
        {data.map((item, index) => (
          <div
            className={`relative flex flex-col justify-start w-full h-auto group first:pl-4 md:first:pl-0 last:pr-4 md:last:pr-0`}
            key={index}
          >
            <div
              className={`group-hover:scale-110 transition-all duration-200 group-first:ml-2 md:group-first:ml-0 group-last:-translate-x-14 md:group-last:-translate-x-2/4 py-8 px-4 flex justify-center rounded-full text-4xl text-white font-bold leading-none w-[100px] h-[100px] flex-shrink-0 absolute -mt-[60px] -translate-x-2/4 left-[50%] ${item.color}`}
            >
              {item.id}
            </div>
            <div className="relative overflow-hidden rounded-lg shadow-md h-full">
              <div className="bg-[radial-gradient(62px_55px,#0000_98%,#ffffff)] bg-[100%] h-[120px] mt-[-60px]" />
              <div className="flex flex-col gap-9 items-center pt-9 px-9 pb-14 bg-white min-w-[282px] h-full">
                <h5
                  className="text-[24px] leading-8 font-semibold text-center text-cGrey"
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                />
                <div className="self-center border border-[#dedede] w-[118px] h-[2px]" />
                <p
                  className="text-xl text-center font-bold text-black"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionApplicationProcess;
