import React, { useEffect, useRef, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LogoUrbanCare from "shared/Logo/LogoUrbanCare";
import SocialsIcon from "shared/SocialsIcon/SocialsIcon";

const FooterAlt: React.FC = () => {
  let [cookieOpen, setCookieOpen] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");

    if (!isCookieAccepted) {
      setCookieOpen(true);
    }
  }, []);

  const closeModal = () => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    localStorage.setItem("cookieAccepted", "true");

    setCookieOpen(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    // const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    if (currentScrollPos + windowHeight >= 1000) {
      containerRef.current.classList.remove("gdpr-control");
    } else {
      containerRef.current.classList.add("gdpr-control");
    }
  };

  return (
    <>
      <div className="FooterAlt relative">
        <div className="container">
          <div className="flex justify-center text-center gap-12 lg:gap-9 py-10 lg:py-[60px] border-y border-[#cdc8e0] border-opacity-50">
            <div className="flex flex-col order-2 lg:order-1 text-lg">
              <h5 className="text-2xl font-bold text-black mb-5">
                BİZİ SOSYAL MEDYADA TAKİP ET
              </h5>
              <div className="col-span-2 flex items-center md:col-span-3 self-center">
                <SocialsIcon rounded className="flex items-center" />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-y-3 justify-between text-base w-full font-medium py-6">
            <span className="flex flex-col md:flex-row gap-3 text-gray-400">
              <a
                href="/uyelik-sozlesmesi"
                rel="noopener"
                title="Üyelik Sözleşmesi"
                className="text-cGrey2 hover:text-main transition-colors"
              >
                Üyelik Sözleşmesi
              </a>
              <span className="hidden md:block">|</span>
              <a
                href="/kvkk"
                rel="noopener"
                title="KVKK Aydınlatma Metni"
                className="text-cGrey2 hover:text-main transition-colors"
              >
                KVKK Aydınlatma Metni
              </a>
              <span className="hidden md:block">|</span>
              <a
                href="/cerezlerin-islenmesi"
                rel="noopener"
                title="Çerezlerin İşlenmesi"
                className="text-cGrey2 hover:text-main transition-colors"
              >
                Çerezlerin İşlenmesi
              </a>
            </span>
            <span className="text-cGrey2">
              © Copyright 2024, URBAN Care Young Talent Platform | Created by{" "}
              <a
                href="https://btkare.com/"
                target="_blank"
                rel="noopener"
                title="Btkare Digital Solutions"
                className="font-bold hover:text-main transition-colors"
              >
                Btkare
              </a>
            </span>
          </div>
        </div>
      </div>

      {/* Cookie */}
      <div className="relative z-50 overflow-hidden">
        <div
          className={`fixed left-0 bottom-0 transition-all duration-300 gdpr-control ${
            !cookieOpen ? "-translate-x-full" : ""
          }`}
          ref={containerRef}
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-2xl transition-all">
              <div className="mt-2">
                <p className="text-base text-cGrey font-medium">
                  Sitemizde, deneyimlerinizi kişiselleştirmek ve site kullanım
                  analizi amacıyla çerezler kullanılmaktadır.
                </p>
              </div>

              <div className="mt-4">
                <ButtonSecondary className="!py-3 text-sm" onClick={closeModal}>
                  Kabul Et
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAlt;
