import React, { useEffect } from "react";
import SectionHero from "components/SectionHero/SectionHero";
import SectionAboutProgram from "components/SectionAboutProgram/SectionAboutProgram";
import SectionApplicationProcess from "components/SectionApplicationProcess/SectionApplicationProcess";
import SectionTeam from "components/SectionTeam/SectionTeam";
import SectionFaq from "components/SectionFaq/SectionFaq";
import bondPlex from "images/urbancare-gokce-irten-bond-plex.jpg";

function PageHome() {
  useEffect(() => {
    const handlePageReload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handlePageReload);

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []);

  return (
    <>
      <div className="page-home relative overflow-hidden">
        <div className="container relative space-y-20 lg:space-y-[120px] mb-20 lg:mb-[120px]">
          <SectionHero className="h-[calc(100svh-125px)] lg:h-[calc(100svh-100px)] min-h-[510px] md:min-h-[785px] justify-center" />
          <SectionAboutProgram className="pt-4" />
          <img
            src={bondPlex}
            alt="Urban Care x Gökçe İrten | Bond Plex Bağ Güçlendirici"
            title="Urban Care x Gökçe İrten | Bond Plex Bağ Güçlendirici"
          />
        </div>

        <div className="relative mb-20 space-y-20 lg:space-y-[120px] lg:mb-[120px]">
          <SectionTeam />
          <SectionApplicationProcess headCenter />
        </div>

        <div className="container relative mb-20 lg:mb-28">
          <SectionFaq />
        </div>
      </div>
    </>
  );
}

export default PageHome;
