import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface PageGDPRProps {
  className?: string;
}

const PageGDPR: FC<PageGDPRProps> = ({ className = "" }) => {
  return (
    <div className={`page-gdpr ${className}`}>
      <Helmet>
        <title>Urban Care Young Talent Platform | KVKK Aydınlatma Metni</title>
      </Helmet>

      <div className={`container py-14 md:py-24 ${className}`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <div
            className={`section-heading relative flex flex-col sm:flex-row justify-between`}
          >
            <div className="text-center w-full max-w-[60rem] mx-auto">
              <div className="relative p-0">
                <h2 className={`default-heading mb-0 items-center`}>
                  URBAN CARE YOUNG TALENT PLATFORM KİŞİSEL VERİLERİN İŞLENMESİNE
                  YÖNELİK İNTERNET SİTESİ AYDINLATMA METNİ
                </h2>
                <div
                  className={`mt-3 !max-w-[220px] md:max-w-[unset] w-full h-[2px] left-0 -bottom-3 m-auto bg-main`}
                />
              </div>
            </div>
          </div>
          <div className="gdpr-wrapper">
            <p className="text-left">
              <strong>Selen Kozmetik Anonim Şirketi (“Selen Kozmetik”</strong>{" "}
              veya <strong>“Urban Care”)</strong> olarak kişisel verilerinizin
              korunmasına değer veriyor, dolayısıyla sizin de aynı hassasiyeti
              göstermenizi bekliyoruz. Bu sebeple hangi kişisel verilerinizi ne
              amaçla kullandığımızı detaylarıyla anlatan bu metni okumanız,
              bunların idari ve teknik güvenliğinden{" "}
              <strong>“Veri Sorumlusu”</strong> sıfatıyla sorumlu olan{" "}
              <strong>Urban Care</strong> yükümlülüklerini net bir şekilde
              anladığınızı göstermeniz bizim için çok önemlidir.
            </p>
            <h4>Neden Bu Metni Okuyorum?</h4>
            <p>
              <strong>7 Nisan 2016</strong> tarihinde yürürlüğe giren{" "}
              <strong>
                6698 sayılı Kişisel Verilerin Korunması Kanununun (“KVKK”) 10.
                maddesi,
              </strong>
              kişisel verileri işlenen (kullanılan) kişilerin{" "}
              <strong>(KVKK’da ilgili kişi olarak ifade edilmektedir)</strong>{" "}
              bu verileri kullanan <strong>“Veri Sorumlusu”</strong> tarafından
              bilgilendirilmesini zorunlu kılmaktadır. Bizler de{" "}
              <strong>Veri Sorumlusu</strong>
              olarak{" "}
              <span className="underline">
                sizler hakkında işleyeceğimiz kişisel verilerle ilgili
                bilgilendirildiğinizi ispat etmek durumundayız.
              </span>
            </p>
            <h4>
              Kullanılan Kişisel Verileriniz Neler Olup Hangi Yollarla Elde
              Edilmektedir?
            </h4>
            <p>
              <strong>Urban Care</strong> olarak aydınlatma metni kapsamındaki
              kişisel verilerinizi, internet sitemizi ziyaret etmeniz veya{" "}
              <strong>Urban Care</strong> dijital yollarla{" "}
              <strong>
                (KVKK’da “otomatik olan” yöntem şeklinde ifade edilmektedir.)
              </strong>{" "}
              elde etmekteyiz. Elde edilen kişisel verilerinizin neler olduğu ve
              elde etmemizin hukuki sebebi aşağıda açıklanmıştır:
            </p>
            <div className=" overflow-x-auto">
              <table>
                <thead>
                  <tr>
                    <th>Veri Kategorisi</th>
                    <th>İşlenen Kişisel Verileriniz</th>
                    <th>Elde Etme Hukuki Sebebi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>İşlem Güvenliği Verisi</td>
                    <td>
                      Trafik bilgisi (İnternet sitesi giriş-çıkış saatiniz, IP
                      adresi bilginiz, port bilginiz)&nbsp;
                    </td>
                    <td className="w-[40%]">
                      &nbsp;Belirtilen bilgileriniz, <strong>Urban Care</strong>{" "}
                      internet sitemizi ziyaretiniz ve mobil uygulamamızı
                      kullanmanız vasıtasıyla{" "}
                      <span className="underline">
                        işlemenin kanunlarda öngörülmesi
                      </span>
                      hukuki sebebiyle elde edilmektedir.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>
              Elde Edilen Kişisel Verileriniz Hangi Amaçla ve Hukuki Sebeple
              Kullanılmaktadır?
            </h4>
            <p>
              <strong>Urban Care</strong> tarafından aşağıda yer verilen
              tabloda, aydınlatma yükümlülüğünün yerine getirildiği faaliyet
              kapsamında gerçekleştirilen kişisel veri işleme amaçları
              detaylandırılmıştır. Her bir kişisel veri işleme amacı, amaç
              kapsamında kullanılan kişisel veri kategorileri ve ilgili hukuki
              sebep ile eşleştirilmiştir.
            </p>
            <div className="overflow-x-auto">
              <table>
                <thead>
                  <tr>
                    <th>Kişisel Veri İşleme Amacı</th>
                    <th>İlgili Kişisel Veri Kategorisi</th>
                    <th>İşleme Hukuki Sebebi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-[50%]">
                      <strong>
                        5651 sayılı İnternet Ortamında Yapılan Yayınların
                        Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla
                        Mücadele Edilmesi Hakkında Kanun
                      </strong>{" "}
                      uyarınca Urban Care internet sitesini ziyaretinize ilişkin
                      trafik bilgilerinin kaydedilmesi ve saklanması
                      amaçlarıyla,
                    </td>
                    <td>İşlem güvenliği kategorisindeki verileriniz,</td>
                    <td>
                      <span className="underline">
                        İşlemenin kanunlarda öngörülmesi
                      </span>{" "}
                      hukuki sebebiyle işlenmektedir.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>Kişisel Verileriniz Başkalarına Aktarılıyor Mu?</h4>
            <p>
              Evet, kişisel verilerinizi özetle iş ortaklarımıza, resmî
              kurumlara, denetçilerimize, avukatlarımıza, mali müşavirlerimize
              aktarmak durumunda kalabiliyoruz. Ancak aktarımı yalnızca belirli
              amaçlar çerçevesinde ve bilginiz dahilinde yapıyoruz. Dolayısıyla
              kişisel verileriniz;
            </p>
            <ul>
              <li>
                İnternet sitemiz ve mobil uygulamamız aracılığıyla size
                hizmetlerimizi sunmak, sunduğumuz hizmetlerin kalitesini
                arttırmak, teknik altyapıyı sağlamak ile destek ve kontrolleri
                yürütülmek amacıyla bu konuda anlaşmalı olduğumuz
                tedarikçilerimize ve hizmet sağlayıcılarımıza,
              </li>
              <li>
                Yetkili kurum ve kuruluşlarının talep etmesi halinde mevcut
                kanuni yükümlülüklerimizin yerine getirilmesi amacıyla;
                düzenleyici ve denetleyici kurumlara ve kanunlarda açıkça
                kişisel verileri talep etmeye yetkili olan kamu kurum veya
                kuruluşlarına,
              </li>
              <li>
                Hukuk işlerinin takibi amacıyla birlikte çalışmakta olduğumuz
                yasal takip süreçleri ile ilgili zorunlu kişilere ve
                danışmanlarımıza,
              </li>
              <li>
                İş süreçlerinin takibi, devamlılığının sağlanması, denetimi ve
                raporlama süreçlerinin yürütülebilmesi amacıyla iş ortaklarımıza
                ve/veya grup şirketlerimize
              </li>
            </ul>
            <p>aktarılmaktadır. </p>
            <h4>KVKK Size Hangi Hakları Veriyor?</h4>
            <p>
              <strong>KVKK</strong> size kişisel verileriniz üzerinde kontrol
              sağlayabilmeniz amacıyla, (a) kişisel verilerinizin işlenip
              işlenmediğini öğrenme; (b) işlenmişse buna ilişkin bilgi talep
              etme, (c) işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme; (d) varsa yurt içinde veya yurt dışında
              kişisel verilerinizin aktarıldığı üçüncü kişileri bilme; (e)
              kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme; (f) kişisel verilerinizin
              silinmesini veya yok edilmesini isteme gibi ve bunlarla sınırlı
              olmamak üzere bir çok hak sağlamaktadır. Bu hakların tamamını ve
              detaylarını <strong>KVKK</strong>’nın 11. maddesinde
              bulabilirsiniz.
            </p>
            <h4>Haklarınızı Nasıl Kullanabilirsiniz?</h4>
            <p>
              Belirtilen haklarınızı kullanmak isterseniz taleplerinizi bize
              yazılı olarak (örneğin{" "}
              <strong>
                ihtarname veya iadeli taahhütlü mektup vasıtasıyla
              </strong>
              ) veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik
              imza, mobil imza ya da bize daha önce bildirdiğiniz ve
              <strong>sistemimizde</strong> kayıtlı bulunan elektronik posta
              adresinizi kullanmak suretiyle iletebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageGDPR;
