import React, { HTMLAttributes, ReactNode } from "react";
import NextPrev from "shared/NextPrev/NextPrev";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  headingClassName?: string;
  borderClassName?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  border?: boolean;
  borderFull?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  className = "mb-12",
  headingClassName = "",
  isCenter = false,
  hasNextPrev = false,
  border = false,
  borderFull = false,
  borderClassName = "",
  ...args
}) => {
  return (
    <div
      className={`section-heading relative flex flex-col sm:flex-row justify-between ${className}`}
    >
      <div
        className={isCenter ? "text-center w-full max-w-2xl mx-auto" : "block"}
      >
        <div className={`relative  lg:p-0 ${isCenter && "px-4"}`}>
          <h2 className={`default-heading ${headingClassName}`} {...args}>
            {children || `Section Heading`}
          </h2>
          {border && (
            <div
              className={`mt-3 max-w-[220px] md:max-w-[unset] w-full h-[2px] left-0 -bottom-3 m-auto bg-main  ${
                !borderFull && "!max-w-[220px]"
              }
              ${!isCenter && "float-left"}
              ${borderClassName}`}
            />
          )}
        </div>
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500">
            {desc}
          </span>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading;
