import React, { FC } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface ScrollToTopProps {
  children?: React.ReactNode;
}

const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
