import Heading from "components/Heading/Heading";
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  useEffect(() => {
    const handlePageReload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("beforeunload", handlePageReload);

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []);

  return (
    <div className={`page-about ${className}`}>
      <Helmet>
        <title>URBAN Care Young Talent Platform | Hakkımızda</title>
      </Helmet>

      <div className={`container py-14 md:py-24 ${className}`}>
        <div className={`flex flex-col gap-7 lg:gap-12`}>
          <Heading
            border
            isCenter
            desc=""
            className="mb-0 items-center container"
          >
            <span>UYTP</span> HAKKIMIZDA
          </Heading>
          <div className="gdpr-wrapper text-center">
            <p>
              <strong>URBAN Care</strong>, kuruluşundan bu yana tüm gücünü
              gençlerden ve kadınlardan almış, Her zaman önceliği insan, doğa,
              hayvana saygı olan bir markadır.
            </p>
            <p>
              <strong>URBAN Care</strong>’in kuruluşunun 10. yılında marka
              karakteriyle uyumlu olarak gençlerle arasındaki bağı güçlendirmek,
              onlara ilham vermek için Toplum Gönüllüleri Vakfı ( TOG)’ nın iş
              birliğiyle <strong>URBAN Care</strong>
              Young Talent Platformu (UYTP) hayata geçirdi. Bu platform her yıl
              farklı bir konu başlığı seçerek gençlere hayallerini
              gerçekleştirmeleri için bir fon kaynağı sunabilmeyi hedefliyor. Bu
              yıl <strong>URBAN Care</strong> Bond Plex Onarıcı Bakım serisi
              ‘’Bağlarımızla Güçlüyüz’’ hikayesinin ilhamıyla odak konu ‘dans’
              olarak belirlendi. <strong>URBAN Care</strong> Bond Plex Onarıcı
              Bakım serisinin etiketlerini de UYTP etkisiyle sanatçı Gökçe
              İrten’in dans eden kadın figürleriyle yenilendi.
            </p>
            <p>
              ‘Dansta Fırsat Eşitliği’ hikayenin temelini oluşturuyor. Hem
              profesyonel hem de profesyonel olmayanlara yönelik bu platformla
              katılımcıların ufuklarını açarak, dans kariyerlerini inşa etmeleri
              için yurt dışı eğitim fırsatı sunuyor.
            </p>
            <p>
              Platform aynı zamanda yılın ilerleyen kısımlarında da farklı burs
              olanakları sunmayı hedefliyor. Sanat Yönetmeni, Koreograf, Eğitmen
              Beyhan Murphy’nin başkanlığını yaptığı danışma kurulunda ve
              sanatçı, dans ve hareket terapisti Ekin Bernay’ın
              koordinatörlüğüyle büyük titizlikle çalışmalar sürdürülüyor. Ekin
              Bernay, seçici kurul üyeleri ve eğitim sağlanan gençlerle
              birlikte, Anadolu’nun farklı köşelerinde ve özellikle afet
              bölgesinde, gençlere ve kadınlara ilham verebilmek için{" "}
              <strong>URBAN Care</strong> Dans Atölyeleri gerçekleştirilmesi
              hedefleniyor.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
