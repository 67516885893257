import React from "react";
import { Link } from "react-router-dom";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "w-24" }) => {
  return (
    <Link
      to="/"
      className={`logo inline-block focus:outline-none focus:ring-0 ${className}`}
    >
      <LogoSvg />
    </Link>
  );
};

export default Logo;
